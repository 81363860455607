'use strict';

define('vb/private/types/capabilities/fetchByOffset',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/types/dataProviderConstants',
  'vb/private/utils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByOffsetUtils',
  'vb/types/typeUtils',
  'vbc/private/monitorOptions'],
(Log, Constants, DPConstants, Utils, FetchContext, FetchByOffsetUtils, TypeUtils, MonitorOptions) => {
  const FETCH_BY_OFFSET_CAPABILITY = 'fetchByOffset';

  /**
   * fetchByOffset data provider capability types supported
   */
  const FetchByOffsetCapability = {
    FETCH_BY_OFFSET_RANDOM_ACCESS: 'randomAccess',
    FETCH_BY_OFFSET_ITERATION: 'iteration',
  };
  // for list of supported fetch by offset parameters - oj.FetchByOffsetParameters
  const FETCH_BY_OFFSET_PARAMS = [
    /**
     * Optional attributes (aka RT filtered fields) to include in the result. If specified,
     * then 'at least' these set of attributes will be included in each row in the data array
     * in the FetchListResult. If not specified then the default attributes will be included.
     * If the value is a primitive then this is ignored.
     * Expressions like "!" and "@default" are also supported. e.g. ['!lastName', '@default'] for
     * everything except 'lastName'. For only 'firstName' and 'lastName' we'd have ['firstName',
     * 'lastName']. Order does not matter when @default is used with field exclusions "!".
     * This can be nested. e.g. ['!lastName', '@default', {name: 'location', attributes:
     * ['address line 1', 'address line 2']}]
     *
     * Examples:
     * For a employee object with a department (1:1 relationship with employee):
     * 1. array of primitives
     * attributes: ['id', 'firstName', 'lastName'] // id, firstName, lastName
     * attributes: ['id', 'firstName', '!lastName', 'email'] // id, firstName, email only
     * attributes: ['id', 'firstName', '!lastName', '@default', 'email'] // all fields except
     *    lastName, which is more than the requested attributes
     * attributes: ['id', 'firstName', '!lastName', 'email', '@default',
     *   { name: 'dept', attributes: [ 'id', 'deptName' ] } ]
     *
     * @type {Array<string|FetchAttribute>|null}
     * @see http://jet.us.oracle.com/6.2.0/tsdocs/oj.FetchListParameters.html
     * @see http://jet.us.oracle.com/6.2.0/tsdocs/oj.FetchAttribute.html
     * @since JET 6.2
     */
    'attributes',

    /*
     * Optional number of rows to fetch starting from offset.
     * @type {number}
     */
    'size',
    /*
     * Optional offset from which rows need to be fetched.
     * @type {number}
     */
    'offset',
    /*
     * Optional sort criteria to apply.
     * @type {Array<SortCriterion>|null}
     */
    'sortCriteria',

    /*
     * Optional filter criterion to apply. The filter criterion would be composed of a supported
     * FilterOperator such as a AttributeFilterOperator or a CompoundFilterOperator.
     * @type {FilterOperator|null}
     */
    'filterCriterion',

    /**
     * The AbortSignal from AbortController. Optional. A signal associated with fetchByOffset
     * so that this request can be aborted later.
     * @type {AbortSignal|null}
     */
    'signal',
  ];

  /**
   * The upper limit of number of rows fetched during iteration.
   * Note: This is only used when size isn't provided and there is continuous iterating of
   * rows required - example with fetchByKeysIteration.
   * @type {number}
   */
  const DEFAULT_ITERATION_LIMIT = -1;

  /**
   * Object that implements the FetchByOffset contract, both making a fetch call and building
   * results expected by callers of fetchByOffset. See JET oj.DataProvider for details.
   * This implementation uses the fetchFirst implementation for everything except for building
   * final results.
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["whiteListFetchOptions",
   "getFetchCapability", "reconcilePaginateOptionsIterationLimit",
    "reconcilePaginateOptionsOffset", "reconcilePaginateOptionsSize"] }] */
  class FetchByOffset extends FetchContext {
    /**
     *  prune options to just what supported on oj.FetchListParameters; sometimes caller
     *  provides an object with random properties.
     *
     * @param {object=} options Options to control fetch
     * @property {number} options.offset The index at which to start fetching records.
     * @property {number} options.size Optional # of rows to fetch starting from offset. If fewer
     * than that number of rows exist, the fetch will succeed but be truncated
     * @property {Array} options.sortCriteria
     * @property {Object} options.filterCriterion
     * @property {AbortSignal=} options.signal The AbortSignal from AbortController.
     * A signal associated with fetchByOffset so that this request can be aborted later.
     */
    whiteListFetchOptions(options) {
      let o;
      if (options) {
        o = {};
        FETCH_BY_OFFSET_PARAMS.forEach((param) => {
          if (options[param] !== undefined) {
            // allow other falsey values
            o[param] = options[param];
          }
        });
      }
      return o;
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     */
    getFetchCapability() {
      return DPConstants.CapabilityType.FETCH_BY_OFFSET;
    }

    /**
     * builds the paginate options using the SDP state, cached state and fetch call options.
     * @returns {{offset: *, size: *, iterationLimit: null}}
     */
    getPaginateOptions() {
      // (1) Build pagingCriteria options from fetch call and SDP configuration
      const size = this.getPaginateOptionsSize();
      const offset = this.getPaginateOptionsOffset();
      const iterationLimit = this.getPaginateOptionsIterationLimit();
      return { offset, size, iterationLimit };
    }

    /**
     * returns the iterationLimit to use. This capability does not use this property.
     * @returns {*}
     */
    getPaginateOptionsIterationLimit() {
      const sdpValue = this.sdpState.value;
      let variablePCIterationLimit;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCIterationLimit = variablePC.iterationLimit || DEFAULT_ITERATION_LIMIT;
      }
      return variablePCIterationLimit; // can be undefined for externalized fetch
    }

    /**
     * get the size for the paginate transform options. The size is determined as follows:
     * - if size >= 0 provided by fetch call, that gets used
     * - if size is -1 provided by fetch call (implies fetch unlimited rows) then the variable is
     * checked for maxSize. If it is set that is used. Otherwise -1 is used.
     * - if size is not provided by fetch call then the size configured on the SDP variable is
     * used. For external fetches this can be undefined until a later time when this same
     * method is called from reconcileTransformOptions.
     *
     * Subclasses can override this method to have custom behavior for size.
     * @returns {*} size
     */
    getPaginateOptionsSize() {
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;
      let variablePCSize;
      let variablePCMaxSize;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCSize = variablePC.size || FetchContext.DEFAULT_SIZE;
        variablePCMaxSize = variablePC.maxSize;
      }
      let size;
      if (fetchOpts.size >= 0) {
        // size provided by fetch() call always wins
        ({ size } = fetchOpts);
      } else if (fetchOpts.size === -1) {
        // size -1 gets special treatment;
        // use maxSize if variable has a value otherwise use size: -1
        size = variablePCMaxSize || fetchOpts.size;
      } else {
        size = variablePCSize; // can be undefined for externalized fetch
      }

      return size;
    }

    /**
     * get the offset for the paginate transform options.
     * @returns {*} offset
     */
    getPaginateOptionsOffset() {
      let variablePCOffset;
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;

      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCOffset = variablePC.offset || FetchContext.DEFAULT_OFFSET;
      }

      let offset;
      if (fetchOpts.offset >= 0) {
        // caller has set offset in fetch() call, use it
        ({ offset } = fetchOpts);
      } else {
        offset = variablePCOffset; // can be undefined for externalized fetch
      }

      return offset;
    }

    /**
     * Reconcile transform options determined by the fetch capability, with options from
     * other sources. At the moment the RestAction is the only other source this could come from.
     *
     * @param transformOptions transform options as determined from fetch call and SDP defaults.
     * @param restTransformOptions transform options from rest
     *
     * @return {*} reconciled options
     */
    reconcileTransformOptions(transformOptions, restTransformOptions) {
      const reconciledOptions = super.reconcileTransformOptions(transformOptions, restTransformOptions);

      // (1) fix up pagination options employing simple heuristics to reconcile with options
      // coming from other sources
      const tpo = transformOptions.paginate;
      const otpo = restTransformOptions.paginate;
      const recpo = this.reconcilePaginateOptions(tpo, otpo);

      if (recpo) {
        reconciledOptions.paginate = recpo;
      }

      // (2) sort, filter, query options can be arbitrarily complex that it's not feasible to
      // use a simple heristic to merge, besides the super implementation that uses Object.assign.
      // Instead page authors can use the mergeTransformOptions property to decide how to merge.

      return reconciledOptions;
    }

    /**
     * Reconcile the paginate options coming from 2 sources.
     * @param paginateOptions - paginate options determined from fetch call and SDP defaults.
     * @param restPaginateOptions - paginate options as defined on the RestAction. For
     * externalized fetch this is relevant to consider in the final paginate options.
     *
     * @returns {*} the merged result
     */
    reconcilePaginateOptions(paginateOptions, restPaginateOptions) {
      const cpo = paginateOptions;
      const isNullOrUndefined = function (obj) {
        return obj === undefined || obj === null;
      };

      const changedSize = this.reconcilePaginateOptionsSize(paginateOptions, restPaginateOptions);
      if (!isNullOrUndefined(changedSize)
        && (!isNullOrUndefined(cpo.size) || changedSize !== cpo.size)) {
        cpo.size = changedSize;
      }

      const changedOffset = this.reconcilePaginateOptionsOffset(paginateOptions, restPaginateOptions);
      if (!isNullOrUndefined(changedOffset)
        && (!isNullOrUndefined(cpo.offset) || changedOffset !== cpo.offset)) {
        cpo.offset = changedOffset;
      }

      const changedIterationLimit = this.reconcilePaginateOptionsIterationLimit(paginateOptions, restPaginateOptions);
      if (!isNullOrUndefined(changedIterationLimit)
        && (!isNullOrUndefined(cpo.iterationLimit) || changedIterationLimit !== cpo.iterationLimit)) {
        cpo.iterationLimit = changedIterationLimit;
      }

      return cpo;
    }

    /**
     * returns a valid size -1, 0 or positive number if size was changed. Otherwise undefined.
     * @param paginateOptions
     * @param restPaginateOptions
     * @returns {*} changed size or undefined if there is no change
     */
    reconcilePaginateOptionsSize(paginateOptions, restPaginateOptions) {
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      // if size is not set on paginateOptions then,
      // - use size set on rest,
      // - otherwise use default size.
      // if size is -1 then, caller explicitly wants unlimited rows
      // - use maxSize is set on other sources.
      if (!tpo.size && tpo.size !== 0) {
        changed = (rtpo && rtpo.size && rtpo.size >= 0) ? rtpo.size : FetchContext.DEFAULT_SIZE;
      } else if (tpo.size === -1 && rtpo && rtpo.size && rtpo.maxSize) {
        changed = rtpo.maxSize;
      }

      return changed;
    }

    /**
     * returns a valid offset 0 or positive number if changed. Otherwise undefined.
     * @param paginateOptions
     * @param restPaginateOptions
     * @returns {*} changed offset or undefined if there is no change
     */
    reconcilePaginateOptionsOffset(paginateOptions, restPaginateOptions) {
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      // if offset is not set on paginateOptions then,
      // - use offset set on rest options,
      // - otherwise use default offset.
      if (!tpo.offset && tpo.offset !== 0) {
        changed = rtpo && rtpo.offset >= 0 ? rtpo.offset : FetchContext.DEFAULT_OFFSET;
      }

      return changed;
    }

    reconcilePaginateOptionsIterationLimit(paginateOptions, restPaginateOptions) {
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      // if iterationLimit is not set on paginateOptions then,
      // - use iterationLimit set on rest options,
      // - otherwise use default iterationLimit -1.
      if (!tpo.iterationLimit) {
        changed = rtpo && rtpo.iterationLimit > 0 ? rtpo.iterationLimit : DEFAULT_ITERATION_LIMIT;
      }

      return changed;
    }

    /**
     * overridden so the request can be spliced if needed based on capability set on the
     * SDP, and the response can be packed up in a form FetchByOffset contract imposes.
     * @returns {Promise}
     */
    fetch() {
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const cap = this.sdp.getCapability(FETCH_BY_OFFSET_CAPABILITY);

      const isImplementationRandomAccess = function () {
        return cap && cap.implementation === FetchByOffsetCapability.FETCH_BY_OFFSET_RANDOM_ACCESS;
      };

      if (isImplementationRandomAccess()) {
        return Promise.resolve().then(() => {
          const { sdp } = this;
          const { fetchOptions } = this;

          sdp.log.startFetch('ServiceDataProvider', uniqueId,
            'fetchByOffset called with options', JSON.stringify(fetchOptions),
            'and state:', this.sdpState);
          const mo = new MonitorOptions(MonitorOptions.SPAN_NAMES.SDP_FETCH_BY_OFFSET, uniqueId);
          return sdp.log.monitor(mo, (fetchTime) => this.fetchForReal().then((result) => {
            const fetchByOffsetResult = FetchByOffsetUtils.buildFetchResult(this, result);
            sdp.log.endFetch('ServiceDataProvider', uniqueId,
              'fetchByOffset succeeded with result:', fetchByOffsetResult, fetchTime());
            this.clearInternalState();

            return (fetchByOffsetResult);
          }).catch((err) => {
            sdp.log.endFetch('ServiceDataProvider', uniqueId, 'fetchByOffset failed with error:', err,
              fetchTime(err));
            this.clearInternalState();
            // fire a dataProviderNotification event so authors can handle error appropriately
            this.invokeNotificationEvent(Constants.MessageType.ERROR, err);
            throw (err);
          }));
        });
      }

      // we should never get here because SDP will never call this class for iteration based
      // lookups
      const err = `ServiceDataProvider ${uniqueId}: FetchByOffset randomAccess based `
        + 'implementation cannot be used as an iteration based implementation! Check your'
        + ' configuration!';
      this.log.error(err);
      return Promise.reject(err);
    }

    fetchForReal() {
      return super.fetch();
    }

    /**
     * processes the response transform results and looks for known properties(!), and
     * saves off the transform results into the internal state of the variable instance.
     * The only properties in the 'paginate' response transform result we look for are totalSize
     * and hasMore.
     * Any other properties like pagingState, if returned from a response transform function,
     * is held in the internal state and then later passed to the subsequent request transform
     * functions.
     * @param transformResults
     * @private
     */
    processResponseTransforms(transformResults) {
      if (transformResults && Object.keys(transformResults).length > 0) {
        Object.keys(FetchContext.RESPONSE_TRANSFORM_TYPE).forEach((key) => {
          const transformKey = FetchContext.RESPONSE_TRANSFORM_TYPE[key];
          this.setInternalState(transformKey, transformResults[transformKey]);
        });
      }

      // set the totalSize on the SDP property. This is the canonical size of the endpoint
      // when no search / filter criteria is applied. IOW this value is meant to be the same
      // every time a fetch is called. it's ok to set the totalSize on the SDP instance for
      // this reason.
      // Only set size when there is a change - this is because components call fetch
      // repeatedly for scrolling and the same value for totalSize gets set, causing an
      // unnecessary variable writes and queuing of the event (only to be discarded when
      // event is about to fire - variable.js)
      const ts = this.totalSize();
      return this.sdp.getTotalSize().then((sdpSize) => {
        if (ts !== sdpSize) {
          this.sdp.totalSize = ts;
        }
      });
    }

    clearInternalState() {
      const iteratorKey = `${this.id}`;
      const stateValue = Object.assign({}, this.internalState);
      TypeUtils.setPropertyValue(stateValue, iteratorKey, undefined);
      this.internalState = stateValue;
      this.log.finer('iterator', this.id, 'clears internal state on SDP:', this.sdp.id,
        'with key:', iteratorKey);
    }

    /**
     *
     * @returns {boolean} true if there is more results to fetch; false if there are no more
     * results or undefined if there is no information.
     */
    hasMore() {
      const pagingInfo = this.getInternalState(FetchContext.RESPONSE_TRANSFORM_TYPE.PAGINATE);
      const hasMore = pagingInfo && pagingInfo.hasMore;
      return typeof hasMore === 'boolean' ? hasMore : undefined;
    }

    /**
     * Return the total size of data available, including server side if not local.
     *
     * @returns {number} total size of data
     * @instance
     */
    totalSize() {
      const pagingInfo = this.getInternalState(FetchContext.RESPONSE_TRANSFORM_TYPE.PAGINATE);
      return (pagingInfo && pagingInfo.totalSize) || FetchContext.DEFAULT_TOTAL_SIZE;
    }
  }

  return FetchByOffset;
});

