'use strict';

/*
 * Created by cstraub on 10/31/14.
 */
define('vb/private/log',['vb/private/constants', 'vb/private/utils', 'vbc/private/log'], (Constants, Utils, CommonLog) => {
  const vbConfig = window.vbInitConfig || {};
  CommonLog.setConfig(vbConfig.LOG || {});

  // global runtime environment to be shared by all logger instances
  let runtimeEnvironment;

  /**
   * Replace a logger error method with one that calls notifyError on the RuntimeEnvironment
   * @param  {Logger} logger
   */
  const wrapError = (logger) => {
    const errorMethod = logger[Constants.Severity.ERROR];
    if (errorMethod) {
      // Wrap the existing logger in a function that logs and called notifyError
      logger[Constants.Severity.ERROR] = (...args) => { // eslint-disable-line no-param-reassign
        errorMethod(...args);
        // forwards the error to the runtime environment
        runtimeEnvironment.notifyError(...args);
      };
    }
  };

  /**
   * Return true if the RuntimeEnvironment implementation is ours, opposed to the DT version
   * @param  {RuntimeEnvironment} rtEnvironment
   * @return {boolean}
   */
  // eslint-disable-next-line arrow-body-style
  const isRuntimeEnvironment = (rtEnvironment) => {
    // We can use constructor.name here because when live and preview use the debug version which is not mangled.
    return !!(rtEnvironment && Object.getPrototypeOf(rtEnvironment).constructor.name === 'RuntimeEnvironment');
  };

  // Note: There's a small window where the runtime environment will not be ready for notifying errors
  Utils.getRuntimeEnvironment().then((rtEnvironment) => {
    runtimeEnvironment = rtEnvironment;
    if (!isRuntimeEnvironment(runtimeEnvironment)) {
      // Fix the existing error loggers
      const cachedLoggers = CommonLog.getCachedLoggers();
      Object.keys(cachedLoggers).forEach((name) => {
        wrapError(cachedLoggers[name]);
      });
    }
  });

  const wrapErrorIfNeeded = (logger) => {
    if (!isRuntimeEnvironment(runtimeEnvironment)) {
      wrapError(logger);
    }
  };

  /**
   * Loggers are path based, to get a particular logger, use:
   *
   * Log.getLogger('/some/path')
   *
   * where the path is arbitrary but should reflect the organization of your resources.
   *
   * The second argument is used to define custom loggers. A custom logger has name, a severity
   * and a style. A new method with that name is created on the logger that will log a message
   * on the console using that severity and style.
   *
   * Logs can be configured by editing /loggers-config.js, please see the comments in there
   * for more details.
   */
  class Log extends CommonLog {
    /**
     * Returns a logger for the given path (see Log.Loggers). The path will determine the attributes
     * of the logger, including what levels are shown by default.
     *
     * @param {String} path The logger path (i.e. /vb)
     * @param {Object[]} [customLoggers] the definition of custom loggers
     * @param {String} customLoggers.name - the name of the function on the logger
     * @param {String} customLoggers.severity - the severity to use on the custom logger
     * @param {String} customLoggers.style - the style to use on the custom logger
     */
    static getLogger(path, customLoggers) {
      return CommonLog.getLogger(path, customLoggers, wrapErrorIfNeeded);
    }
  }
  return Log;
});

