'use strict';

define('vb/private/types/capabilities/fetchBySingleKey',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/types/dataProviderConstants',
  'vb/private/utils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeysUtils',
  'vb/private/types/capabilities/noOpFetchByKeys',
  'vbc/private/monitorOptions',
  'jsondiff'],
(Log, Constants, DPConstants, Utils, FetchContext, FetchByKeysUtils,
  NoOpFetchByKeys, MonitorOptions, JsonDiff) => {
  const getKeysLength = (keys) => keys && (keys instanceof Set ? keys.size : keys.length);

  /**
   * Convenience class that implements fetching data for a single key.
   * @see oj.DataProvider fetchByKeys for details.
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getFetchCapability"] }] */
  class FetchBySingleKey extends FetchContext {
    /**
     *  prune options to just what supported on oj.FetchListParameters; also when callers
     *  provides more than one key in via 'keys' parameter use just the first.
     *
     * @param {object=} options Options to control fetch
     * @property {number} options.keys Set of keys to fetch
     * @return {Promise} Promise object resolves to a compound object which contains an array of
     * row data objects, an array of ids, and the startIndex triggering done when complete.<p>
     *
     * @param options
     */
    whiteListFetchOptions(options) {
      return FetchByKeysUtils.whiteListFetchOptions(this.sdp, options);
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     */
    getFetchCapability() {
      return DPConstants.CapabilityType.FETCH_BY_KEYS;
    }

    /**
     * overridden so the response can be packed up in a form FetchByKeys contract dictates.
     * @returns {Promise}
     */
    fetch() {
      const keysSet = (this.fetchOptions && this.fetchOptions.keys) || [];
      const keysLen = getKeysLength(keysSet);
      if (keysLen > 1) {
        this.log.info('FetchByKeys using lookup is called with multiple keys', keysSet,
          'when capability only supports fetching by a single key at a time.');
        return this.fetchByMultipleKeys();
      }

      if (keysLen === 0) {
        const uniqueId = `${this.sdp.id} [${this.id}]`;
        this.log.error('FetchByKeys called with an empty set of keys; using a noop FetchByKeys',
          'implementation. Please check the ServiceDataProvider', uniqueId, 'configuration');
        return (new NoOpFetchByKeys(this.fetchOptions)).fetchByKeys();
      }

      return this.fetchBySingleKey();
    }

    /**
     * Called with multiple keys in the fetch parameters, this method fetches one key at a
     * time using the single key capability, as an optimization.
     * @returns {Promise}
     */
    fetchByMultipleKeys() {
      return Promise.resolve().then(() => {
        const { sdp } = this;
        const keysSet = (this.fetchOptions && this.fetchOptions.keys) || [];
        const fetchResults = {};
        const fetchPromises = [];
        const uniqueId = `${this.sdp.id} [${this.id}]`;

        sdp.log.startFetch('ServiceDataProvider', uniqueId,
          'fetchByKeys called with options', this.fetchOptions,
          'and state:', this.sdpState);
        const mo = new MonitorOptions(MonitorOptions.SPAN_NAMES.SDP_FETCH_BY_SINGLE_KEY, uniqueId);
        return sdp.log.monitor(mo, (fetchTime) => {
          keysSet.forEach((key) => {
            const newOpts = Utils.cloneObject(this.fetchOptions);
            newOpts.keys = new Set([key]);
            // @ts-ignore
            const fs = new FetchBySingleKey(this.sdp, newOpts);
            fetchPromises.push(fs.fetchForReal());
          });

          return Promise.all(fetchPromises).then((results) => {
            fetchResults.fetchParameters = this.getFetchOptionsForResponse();
            fetchResults.results = new Map();
            results.forEach((value) => {
              const resultMap = value.results;
              resultMap.forEach((v, k) => {
                fetchResults.results.set(k, v);
              });
            });
            sdp.log.endFetch('ServiceDataProvider', uniqueId,
              'fetchByKeys succeeded with result:', fetchResults, fetchTime());
            return (fetchResults);
          }).catch((err) => {
            sdp.log.endFetch('ServiceDataProvider', uniqueId,
              'fetchByKeys failed with error:', err, fetchTime(err));

            // fire a dataProviderNotification event so authors can handle error appropriately
            this.invokeNotificationEvent(Constants.MessageType.ERROR, err);
            throw (err);
          });
        });
      });
    }

    /**
     * Called with a single key in the fetch parameters.
     */
    fetchBySingleKey() {
      return this.fetchForReal().catch((err) => {
        // fire a dataProviderNotification event so authors can handle error appropriately
        this.invokeNotificationEvent(Constants.MessageType.ERROR, err);

        throw (err);
      });
    }

    /**
     * The real fetch implementation! Both fetchByMultipleKeys and fetchBySingleKey call this
     * @returns {Promise}
     */
    fetchForReal() {
      return Promise.resolve().then(() => {
        const { sdp } = this;
        const { fetchOptions } = this;
        const uniqueId = `${sdp.id} [${this.id}]`;

        sdp.log.startFetch('ServiceDataProvider', uniqueId,
          'fetchByKeys called with options', fetchOptions,
          'and state:', this.sdpState);
        const fetchTime = sdp.log.monitor();

        return super.fetch().then((result) => {
          const fetchByKeysResult = FetchByKeysUtils.buildFetchResult(this, result);
          sdp.log.endFetch('ServiceDataProvider', uniqueId,
            'fetchByKeys succeeded with result:', fetchByKeysResult, fetchTime());

          return (fetchByKeysResult);
        }).catch((err) => {
          sdp.log.endFetch('ServiceDataProvider', uniqueId,
            'fetchByKeys failed with error:', err, fetchTime());

          throw (err);
        });
      });
    }

    /**
     * Pagination options on super class is always set. Here we ensure that it's only set if it's
     * configured on the SDP. Even if it's provided by caller it's not part of the contract for
     * the fetchByKeys API. But we want to respect what page author has set on the configuration.
     * For paginate options that might be coming from an externalized RestAction see
     * reconcileTransformOptions
     * @return {{offset: *, size: *}}
     * @overrides
     */
    getPaginateOptions() {
      return FetchByKeysUtils.getPaginateOptions(this);
    }

    /**
     * Reconcile transform options determined so far with options from other sources such as
     * RestAction. For externalized fetch case both options via fetch call and anything
     * configured on the SDP are ignored. So really we only need to ensure that options are
     * configured on the RestAction itself are used.
     *
     * @param {object} transformOptions transform options as determined from fetch call and SDP defaults.
     * @param {object} restTransformOptions transform options from rest
     *
     * @return {*} reconciled options
     */
    reconcileTransformOptions(transformOptions, restTransformOptions) {
      // get reconciled options as determined by superClass of fetchContext instance
      const superRO = super.reconcileTransformOptions(transformOptions, restTransformOptions);
      return FetchByKeysUtils.reconcileTransformOptions(transformOptions, restTransformOptions, superRO);
    }

    /**
     * Returns the query options built from uriParameters. The query options is passed to the
     * 'query' transforms function via its 'options' parameter.
     * (Applies only for the single key case) When keys are provided by caller via a fetchByKeys
     * call, then the value of the uriParameters is influenced in the following ways:
     *
     * If 'uriParameters' configured on the SDP has a property key that matches the
     * 'idAttribute' set on the same SDP variable (a), then the value of this property is
     * determined as follows in order:
     *   i. use 'keys' parameter if one was provided in the fetchByKeys() call
     *   ii. if not use the uriParameters value as is
     *
     * Note: (a) idAttribute has to be set to a String. Object and Array types are ignored. In
     * the below example, component calls sdp.fetchByKeys({ keys: ['HELLYA'] }). The value of
     * uriParameters.id will be 'HELLYA'
     *
     *   {
     *     uriParameters: { id: 'MEH'},
     *     idAttribute: 'id',
     *     capabilities: {fetchByKeys: { implementation: 'lookup' } }
     *   }
     * @returns {*}
     */
    getQueryOptions() {
      const sdpValue = this.sdpState.value;
      const idAttr = sdpValue[this.sdp.getIdAttributeProperty()];
      let queryParams;
      let replaceKeys;

      if (FetchContext.usePropValue('uriParameters', sdpValue)) {
        replaceKeys = this._getKeysForFetchRequest();
        if (replaceKeys) {
          const clonedUriParams = Utils.cloneObject(sdpValue.uriParameters);
          // always use the first entry in the key since we are dealing with a
          // fetchBySingleKey capability
          [clonedUriParams[idAttr]] = replaceKeys;
          queryParams = clonedUriParams;
        } else {
          queryParams = sdpValue.uriParameters;
        }
      }

      return queryParams;
    }

    _getKeysForFetchRequest() {
      const sdpValue = this.sdpState.value;
      const idAttr = sdpValue[this.sdp.getIdAttributeProperty()];
      let replaceKeys;
      const foptsKeys = (this.fetchOptions && this.fetchOptions.keys);
      const keysLen = getKeysLength(foptsKeys);

      if (FetchContext.usePropValue('uriParameters', sdpValue)) {
        if (keysLen > 0) {
          if (idAttr && typeof idAttr === 'string') {
            const uriIdParamVal = sdpValue.uriParameters && sdpValue.uriParameters[idAttr];
            if (uriIdParamVal) {
              if (JsonDiff.diff([uriIdParamVal], foptsKeys)) {
                // using keys that are different
                this.log.fine('using the keys', foptsKeys,
                  'passed in via fetch call, instead of the value', uriIdParamVal,
                  'set on property uriParameters', idAttr);
              }
              replaceKeys = foptsKeys;
            }
          }
        }
      }

      return replaceKeys;
    }
  }

  return FetchBySingleKey;
});

