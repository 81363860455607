'use strict';

/**
 * the versions.json is generated in dist/package, requires using our grunt tasks to generate.
 */
define('vb/versions',['text!vbVersions/versions.json'], (versions) => {
  const vbVersions = JSON.parse(versions);

  window.vb = window.vb || {};
  Object.assign(window.vb, vbVersions.visualDevelopmentPlatform);
  window.vb.jet = vbVersions.jet;
  window.vb.workbox = vbVersions.workbox;

  return vbVersions;
});

