'use strict';

define('vb/private/translations/bundleProxies',[
	'vb/private/log',
], (
	Log,
) => {
	const logger = Log.getLogger('/vb/private/translations/bundleProxies');

	/**
	 * Proxy Handler for placeholder properties.
	 */
	class BundleHandler {
		constructor(name) {
			this._name = name;
			this._value = {};
		}

		/**
		 * Get a proxied value for the property
		 * @param {object} target map of key/value
		 * @param {string} property the key whose value to retrieve
		 * @param {object} receiver the BundlePropertyHandler
		 * @returns
		 */
		// @ts-ignore
		get(target, property, receiver) {
			logger.info(this._name, '.get(', property, ')');

			const special = this._get(target, property);
			if (special) {
				return special.value;
			}

			// Skip anything already defined
			if (Reflect.has(target, property)) {
				logger.info('\tskipping property');
				return Reflect.get(target, property);
			}

			// Just pass through the Jasmine 'properties' that are requested on values being tested
			// Also knockout 'properties'
			switch (property) {
				case 'asymmetricMatch': // 'Jasmine requested'
				case 'jasmineToString': // 'Jasmine requested'
				case '__ko_proto__': // 'Knockout requested'
					logger.finer('\tskipping property');
					return Reflect.get(target, property);

				default:
					break;
			}

			// Create a Property Proxy for any property not yet encountered
			if (!this._value[property]) {
				this._value[property] = this._createPropertyProxy(target, property);
			}

			// Return the getter.
			return this._value[property];
		}

		/**
		 * Return Bundle Property special cases
		 * @param {object} target map of key/value
		 * @param {string} propertyName the key whose value to retrieve
		 * @returns {object | undefined} with .value property or undefined
		 */
		// @ts-ignore
		_get(target, propertyName) {
			return undefined;
		}

		/**
		 * Create a proxy for the property.
		 * @param {object} target map of key/value
		 * @param {string} propertyName the key to proxy
		 * @returns
		 */
		// @ts-ignore
		_createPropertyProxy(target, propertyName) {
			throw Error('_createPropertyProxy is not implemented');
		}
	}

	/**
	 * V1 Bundle Handler
	 * V1 Bundles are nested maps of key to [string | map]
	 */
	class BundleV1Handler extends BundleHandler {
		_createPropertyProxy(target, propertyName) {
			logger.info('\tcreating v1 property proxy');

			const propertyProxy = new Proxy({}, new BundleV1PropertyHandler(propertyName));
			Object.defineProperty(target, propertyName, {
				get: () => propertyProxy,
				enumerable: true,
				configurable: true,
			});
			return propertyProxy;
		}
	}

	const PLACEHOLDER_STRING = '';
	const SUBSTITUTE_TARGET = new String(PLACEHOLDER_STRING);

	/**
	 * Proxy Handler for a Bundle property to return a placeholder any subproperties.
	 */
	class BundleV1PropertyHandler extends BundleV1Handler {
		/**
		 * Return Bundle Property special cases
		 * @param {object} target map of key/value
		 * @param {string} propertyName the key whose value to retrieve
		 * @returns {object | undefined} with .value property or undefined
		 */
		_get(target, propertyName) {
			if (typeof propertyName === 'symbol') {
				// Intercept toPrimitive, and return the placeholder
				if (propertyName === Symbol.toPrimitive) {
					logger.info('\tintercepting symbol');
					return (hint) => (hint === 'number') ? NaN : PLACEHOLDER_STRING;
				}

				logger.info('\tskipping symbol');
				return { value: Reflect.get(target, propertyName) };
			}

			// Intercept String methods and properties
			// This is to make sure conversion/coercion works.  e.g.
			//  String(bundle.property)
			//  '' + bundle.property
			//  bundle.property.toString()
			if (Reflect.has(SUBSTITUTE_TARGET, propertyName)) {
				const propertyValue = Reflect.get(SUBSTITUTE_TARGET, propertyName);
				const propertyType = typeof propertyValue;

				logger.info('\tsubstituting String property type', propertyType);
				if (propertyType === 'function') {
					return { value: propertyValue.bind(SUBSTITUTE_TARGET) };
				}
				return { value: propertyValue };
			}

			// Intercept indices
			if (Number.isInteger(Number.parseInt(propertyName))) {
				logger.info('\tintercepting index');
				return { value: PLACEHOLDER_STRING[0] };
			}

			return undefined;
		}
	}

	/**
	 * V2 Bundle Handler.
	 * V2 Bundles are a flat map of key to function.
	 */
	class BundleV2Handler extends BundleHandler {
		_createPropertyProxy(target, propertyName) {
			logger.info('\tcreating v2 property proxy');

			// Function that returns the placeholder string
			const propertyProxy = () => PLACEHOLDER_STRING;
			Object.defineProperty(target, propertyName, {
				get: () => propertyProxy,
				enumerable: true,
				configurable: true,
			});
			return propertyProxy;
		}
	}

	return {
		PLACEHOLDER_STRING,
		createBundleV1Proxy: (bundleName) => new Proxy({}, new BundleV1Handler(bundleName)),
		createBundleV2Proxy: (bundleName) => new Proxy({}, new BundleV2Handler(bundleName)),
	};
});

