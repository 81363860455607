'use strict';

define('vb/private/vx/baseExtensionAdapter',[
  'vb/private/log',
  'vb/private/configuration',
  'vb/private/utils',
  'urijs/URI',
  'vbc/private/monitorOptions',
], (Log, Configuration, Utils, URI, MonitorOptions) => {
  const logger = Log.getLogger('/vb/stateManagement/vx/extensionAdapter', [
    // Register a custom logger
    {
      name: 'greenInfo',
      severity: 'info',
      style: 'green',
    },
  ]);

  /**
   * The query param name used by DT to pass the extension version to use in the preview
   * @const {String}
   */
  const DT_EXT_VERSION_QUERY_PARAM = 'vbdt:preferExtensionVersion';

  class BaseExtensionAdapter {
    /**
     * @param  {String} registryUrl  the url of the extension manager
     */
    constructor(registryUrl) {
      this.log = logger;
      /** @type {String} */
      this.registryUrl = registryUrl;
    }

    /**
     * Calculate the preferred version to use when loading extension
     *
     * @return {String} the version
     */
    static getExtensionVersion() {
      // Precedence is given to dev version that is specified using _preferExtensionVersion query parameter.
      // If query param is not specified, use the FA sandbox id.
      const queryParams = new URI(window.location.href).search(true);
      let version = queryParams[DT_EXT_VERSION_QUERY_PARAM];

      if (version) {
        // Since the param is removed from the URL, store the version in the session storage so that on refresh,
        // we can still access it.
        window.sessionStorage.setItem(DT_EXT_VERSION_QUERY_PARAM, version);
      } else {
        // If the version is on the sessionStorage, this has precedence over the sandbox id.
        version = window.sessionStorage.getItem(DT_EXT_VERSION_QUERY_PARAM);
        if (!version) {
          version = Configuration.getSandboxId();
          if (version) {
            // Add the $ in front of the FA sandbox id and encode it
            version = `$${encodeURIComponent(version)}`;
          }
        }
      }

      return version;
    }

    /**
     * @param {Request} request
     */
    getFetchManifestPromise(request) {
      const mo = new MonitorOptions(
        MonitorOptions.SPAN_NAMES.LOAD_EXTENSION_MANIFEST, `extension manifest load ${request.url}`,
      );
      return this.log.monitor(mo, (registryLoadTimer) => fetch(request)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Response not OK when fetching extension at: ${request.url}`);
          }

          this.log.greenInfo('Extension manifest loaded', registryLoadTimer());

          return response.json();
        }));
    }
  }

  return BaseExtensionAdapter;
});

