'use strict';

define('vb/private/services/transformsUtils',[], () => {
  const REQUEST_TRANSFORMS_TYPE_BODY = 'body';
  const REQUEST_TRANSFORMS_TYPE_FETCHBYKEYS = 'fetchByKeys';

  class TransformsUtils {
    /**
     * sort callback that sorts 'body' transform last
     * @param a
     * @param b
     * @returns {number}
     */
    static bodyTransformLast(a, b) {
      if (a === REQUEST_TRANSFORMS_TYPE_BODY) {
        return 1;
      }
      if (b === REQUEST_TRANSFORMS_TYPE_BODY) {
        return -1;
      }
      return 0;
    }

    /**
     * filter callback function to exclude fetchByKeys
     * @param a
     * @returns {boolean}
     */
    static excludeFetchByKeys(a) {
      return (a !== REQUEST_TRANSFORMS_TYPE_FETCHBYKEYS);
    }

    /**
     * sort callback that sorts select transform to be first in list of transforms
     * @param a
     * @returns {number}
     */
    static selectTransformFirst(a) {
      return (a === 'select' ? -1 : 1);
    }

    /**
     * Checks if any of the transforms needs access to the endpoint responses metadata.
     * By default we assume it does not need it.
     * @returns {boolean}
     */
    static transformsUseResponsesMetadata(transformsMetadataFuncs, configuration) {
      if (transformsMetadataFuncs && transformsMetadataFuncs.requirements) {
        const requirements = transformsMetadataFuncs.requirements.call(null, configuration);
        if (requirements && requirements.usesResponsesMetadata === false) {
          return false;
        }
      }
      return true;
    }
  }

  return TransformsUtils;
});

