/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/action/actionChain',[
  'vb/private/action/actionChainUtils',
  'vb/private/action/actionRunner',
  'vb/action/action',
  'vb/private/stateManagement/scope',
  'vb/private/stateManagement/redux/storeManager',
  'vb/private/stateManagement/stateUtils',
  'vb/private/utils',
  'vb/private/stateManagement/context/actionChainContext',
  'vb/private/constants',
  'vb/private/action/assignmentHelper',
  'vb/private/debug/actionChainDebugStream',
  'vbc/private/monitorOptions',
], (ActionChainUtils, ActionRunner, Action, Scope, StoreManager, StateUtils, Utils, ActionChainContext,
  Constants, AssignmentHelper, ActionChainDebugStream, MonitorOptions) => {
  const logger = ActionChainUtils.getLogger();

  const PARAMETERS_VARIABLE_KEY = 'vb_parameters';

  class ActionMonitorOptions extends MonitorOptions {
    constructor(actionId, actionDef, action) {
      super(MonitorOptions.SPAN_NAMES.ACTION, `${actionDef.module} ${action.logLabel}`);
      this.addTags(() => ({
        actionId,
        actionType: actionDef.module,
      }));
    }
  }

  class ActionChainMonitorOptions extends MonitorOptions {
    constructor(chainId, rootAction, logLabel) {
      super(MonitorOptions.SPAN_NAMES.ACTION_CHAIN, `action chain ${logLabel}`);
      this.addTags(() => ({
        chainId,
        rootAction,
      }));
    }
  }

  /**
   * An ActionChain is a graph of one or more Actions that are executed using the supplied
   * context.
   */
  class ActionChain extends Action {
    /**
     * Builds a new action chain instance with page model metadata. This will create the proper context
     * for an action chain to run. An action chain should not be created manually, it should be created
     * by the static helper method ('start()').
     *
     * @private
     * @param id The ID for the action chain
     * @param metadata The action chain metadata as defined in the page model
     */
    constructor(id, metadata) {
      super(id);

      this.log = logger;
      this.id = id;

      this.constantDefs = metadata.constants || {};

      // variables will be created and initialized when we start the action chain
      this.variableDefs = metadata.variables;

      // store the list of actions
      this.actionDefs = metadata.actions;

      // the starting action
      this.rootAction = metadata.root;

      // optional return type
      this.returnType = metadata.returnType;

      // optional array of outcomes
      this.outcomes = metadata.outcomes;

      // The unique option create a store with a unique name.
      // The silent option is used when value change listener are not needed.
      this.scope = Scope.createScope(`chain_${id}`, null, { unique: true, silent: true });

      // storage for action results; results are stored in the 'variables' namespace
      this.scope.results = this.scope.createVariable(Constants.RESULTS_VARIABLE_KEY,
        Constants.VariableNamespace.VARIABLES, 'object', {});

      // storage for action parameters; action parameters are stored in the 'variables' namespace
      this.scope.createVariable(PARAMETERS_VARIABLE_KEY, Constants.VariableNamespace.VARIABLES, 'object', {});

      this.variables = this.scope.variableNamespaces[Constants.VariableNamespace.VARIABLES];

      // this is the $chain variable, and contains variables, metadata, and results.
      // injectContext will add additional properties from the calling context.
      this.expressionContext = new ActionChainContext(this);

      // functions to call at the end, unconditionally; only actions that have access to the chain can add these
      // contains { actionId, name, fnc }
      this.finallyCallbacks = [];

      // will be determined in injectContext to keep track of the scope resolver for the
      // target container in which the action chain will run
      this.targetScopeResolver = null;

      // create a default debug stream so we don't break the unit tests
      this.debugStream = new ActionChainDebugStream();
    }

    /**
     *
     * @param executionContext
     * @param params
     * @returns {Promise<unknown>}
     */
    run(executionContext, params) {
      this.injectContext(executionContext);

      let chainError;
      return this.start(params, this.callingContexts)
        .catch((e) => {
          chainError = e;
        })
        .then((result) => {
          // if we caught an error above, throw that
          if (chainError) {
            throw chainError;
          }
          // otherwise, return the original chain's result, not the event handler's chain
          return result;
        });
    }

    injectContext(executionContext) {
      this.executionContext = executionContext;

      this.context = ActionChainUtils.getInternalContext(executionContext) || {};

      this.callingContexts = this.context.callingContexts;

      this.targetScopeResolver = this.context.targetContainer ? this.context.targetContainer.scopeResolver : null;

      // the set of all Contexts and shortcuts, available for expressions:
      // $chain = expressionContext, $variables = $chain.variables, $metadata = $chain.metadata
      this.availableContexts = ActionChainContext.getAvailableContexts(this, executionContext);

      // make availableContexts available to the debug stream
      this.context.availableContexts = this.availableContexts;

      // get the debug stream from the execution context or use the default one
      this.debugStream = this.context.debugStream || this.debugStream;

      // only execute this code when the debugger is installed
      if (this.debugStream.isDebuggerInstalled) {
        // set up the locator for looking up the chain on the debugger side
        this.debugStream.setChainLocator(this.context.targetContainer.getResourcePath());
      }
    }

    /**
     * Execute the action chain beginning with the root action.
     *
     * @param params the params that this action chain is called with
     * @param callingContexts the contexts of the caller which can be another action chain
     * @return {Promise<Object>}  resolves with an outcome object when the action chain has completed.
     */
    start(params, callingContexts) {
      return Promise.resolve().then(() => {
        // initialize constants and variables to the input parameters or their default values
        this.initializeConstants(params);
        return this.initializeVariables(params, callingContexts)
          .then(() => {
            StoreManager.addScopeToStore(this.scope);
            this.activateVariables();
            this.log.startChain('Starting action chain', this.logLabel, 'with parameters:',
              params);
            const mo = new ActionChainMonitorOptions(this.id, this.rootAction, this.logLabel);
            return this.log.monitor(mo, (totalTime) => {
              this.totalTime = totalTime;

              // debug the start of the action chain
              return this.debugStream.start().then(() => this.runActionStep(this.rootAction));
            });
          });
      })
        .then((result) => {
          this.end();
          return result;
        })
        .catch((error) => {
          this.end(error);
          throw error;
        });
    }

    /**
     * Initialize constants from declaration.
     * TODO: when ActionChain subclasses Container this code must be removed
     */
    initializeConstants(inputParamValues = {}) {
      const scopeResolver = this.targetScopeResolver;

      // go through the constants and create each one.
      // this assumes the constants definition has been filtered by initDefault()
      Object.keys(this.constantDefs).forEach((constantName) => {
        const constantDef = this.constantDefs[constantName];

        // get the initial value if any
        const inputParamValue = inputParamValues && inputParamValues[constantName];

        if (Utils.isExtendedType(inputParamValue)) {
          throw new Error(`Constant '${constantName}' cannot be an extended type.`);
        } else if (Utils.isInstanceType(constantDef.type)) {
          throw new Error(`Constant '${constantName}' cannot be a built-in type.`);
        } else {
          this.createConstant(constantName, constantDef, scopeResolver, inputParamValue);
        }
      });
    }

    /**
     * Create a constant and store it in the scope
     * @param  {String}    constantName    the name of the constant
     * @param  {Object}    constantDef     the definition of the constant
     * @param  {Object} scopeResolver   the scope resolver object (application, page, ...)
     * @param  {*}         inputParamValue the fromCaller or fromUrl value
     */
    createConstant(constantName, constantDef, scopeResolver, inputParamValue) {
      // determine the default value for this constant then create the constant
      const defaultValue = StateUtils.createNonInstanceTypeDefaultValue(constantName, constantDef,
        scopeResolver, this.availableContexts);

      this.scope.createConstant(constantName, Constants.VariableNamespace.CONSTANTS,
        constantDef.type, defaultValue, inputParamValue);
    }

    /**
     * Create the chain variables and initialize them to their default values or initial values (input parameters).
     * TODO: when ActionChain subclasses Container Container this code must be removed
     *
     * @param {Object} initialValues parameters to the action chain
     * @param {Object} callingContexts the contexts of the caller which can be another action chain
     * @param {String} namespace
     * @returns {Promise}
     */
    initializeVariables(initialValues = {}, callingContexts, namespace = Constants.VariableNamespace.VARIABLES) {
      if (!this.variableDefs) {
        return Promise.resolve();
      }

      const promises = [];
      const scopeResolver = this.targetScopeResolver;

      Object.keys(this.variableDefs).forEach((variableName) => {
        const variableDef = this.variableDefs[variableName];

        // A variable and constant cannot have the same name
        if (this.constantDefs[variableName]) {
          promises.push(Promise
            .reject(new Error(`Variable '${variableName}' cannot have the same name as a constant.`)));
        } else {
          let refVariablesAdded;
          // get the initial value if any
          const initialValue = initialValues && initialValues[variableName];

          // if the value is an instance of an extended class, e.g., ServiceDataProvider, we need to look up the
          // actual referenced variable and the instance properties variable and create reference variables
          // for them in order to support pass-by-reference.
          if (Utils.isExtendedType(initialValue)) {
            refVariablesAdded = this.addReferenceVariables(initialValue, variableName, namespace, callingContexts);
          }

          if (!refVariablesAdded) {
            const promise = StateUtils.createVariableDefaultValue(variableName, variableDef, scopeResolver,
              this.scope, this.availableContexts, namespace).then((defaultValue) => {
              const type = StateUtils.getType(variableName, variableDef, scopeResolver);
              this.scope.createVariable(variableName, namespace, type, defaultValue, initialValue);
            });

            promises.push(promise);
          }
        }
      });

      return Promise.all(promises);
    }

    /**
     * Activate all variables for the current container  (chain scope).
     * TODO: when ActionChain subclasses Container this code must be removed
     *
     * @private
     */
    activateVariables() {
      this.scope.activateVariables();
      // currently we do not allow variables defined in actions chains to be extended, for that matter we
      // don't allow extending chains, so activateVariables doesn't have to be called on extensions
    }

    /**
     * Given an instance of an extended class, e.g., ServiceDataProvider, look up its instance variable and
     * instance properties variable and create and add reference variables pointing to them to the chain scope.
     *
     * @param instance an instance of an extended class, e.g., ServiceDataProvider
     * @param referenceVariableName the name for the reference variable
     * @param namespace namespace for the reference variable
     * @param currentContexts the current contexts of the running chain
     * @returns {Boolean}
     */
    addReferenceVariables(instance, referenceVariableName, namespace, currentContexts) {
      const { id } = instance;
      let added;

      if (id) {
        const addRefVar = (context) => {
          if (context) {
            const variable = context.getVariable(id, Constants.VariableNamespace.VARIABLES);
            const value = variable && variable.getValue();

            // instance can be a proxy so we need to check against the actual instance via
            // CHAIN_PROXY_TARGET symbol
            if (value === instance || value === instance[Constants.CHAIN_PROXY_TARGET]) {
              // look up the instance properties variable whose name ends with _value
              const propVariable = context.getVariable(`${id}${Constants.BuiltinVariableName.VALUE}`,
                Constants.VariableNamespace.VARIABLES);

              if (propVariable) {
                // create a reference variable that points to the instance variable
                this.scope.createReferenceVariable(referenceVariableName, namespace, variable);

                // create a reference variable that points to the instance properties variable
                this.scope.createReferenceVariable(`${referenceVariableName}${Constants.BuiltinVariableName.VALUE}`,
                  namespace, propVariable);

                return true;
              }
            }
          }

          return false;
        };

        // first search the available contexts of the calling container
        const callingContexts = this.context.container.getAvailableContexts();
        added = ['$fragment', '$page', '$flow', '$application', '$global']
          .some((contextName) => addRefVar(callingContexts[contextName]));

        // if not found, the instance is a local variable defined on the chain so search
        // $chain of the chain's contexts
        if (!added) {
          added = addRefVar(currentContexts.$chain);
        }
      }

      if (!added) {
        throw new Error(`Failed to add reference variables for ${referenceVariableName}.`);
      }

      return true;
    }

    end(error) {
      if (this.totalTime) {
        this.log.endChain('Ending action chain', this.logLabel, 'successfully', this.totalTime(error));
      }

      // close the debug stream
      this.debugStream.end();

      this.dispose();
    }

    /**
     * Create a scope to store the action parameters and provide isolation between action steps
     * and returns an array of values/getters.
     * Each parameter is defined as a constant so that the value is not stored in redux store.
     * @param  {Action} action            the action instance
     * @param  {Object} actionParams      the parameter definition, object where each property is a parameter
     * @param  {Object} availableContexts the contexts for expression evaluation
     * @return {Array}                    an array with the parameter values
     */
    static createActionParameters(action, actionParams = {}, availableContexts) {
      const id = `parameters_${action.id}`;
      const keys = Object.keys(actionParams);

      // Only create the scope if there is at least one parameter
      if (keys.length === 0) {
        return [];
      }

      const scope = Scope.createScope(id, null, { silent: true });

      // Traverse the action parameters definition and create a constant in the action scope for each parameter
      keys.forEach((key) => {
        const defaultValue = action.constructor.buildParamValue(key, actionParams[key], availableContexts);

        // Constant is created using freeze: false so that their value are allowed to mutate
        scope.createConstant(key, Constants.VariableNamespace.CONSTANTS, 'any', defaultValue, undefined, null,
          { freeze: false });
      });

      return scope.variableNamespaces[Constants.VariableNamespace.CONSTANTS];
    }

    /**
     * Runs a particular action with the given ID. The ID must be part of the action chain that this class is
     * associated with.
     *
     * @param {Object} aid The ID of the action to accept
     * @param {Object} availableContexts
     * @returns {Promise<Object>} a promise that resolves with an outcome object
     */
    runActionStep(aid, availableContexts = this.availableContexts) {
      // used to measure setup time for the action
      const setupTime = this.log.monitor();

      let actionId = aid;
      // this method takes in the action ID, however we had previously supported the outcomes explicitly providing
      // the action definition. this bit of code provides backwards compatiblity. When we want to stop supporting
      // the old syntax, we will always lookup the actionDef from the actionId
      let actionDef;
      if (typeof actionId === 'string') {
        // this is forward looking code
        actionDef = this.actionDefs[actionId];
        if (!actionDef) {
          throw new Error(`The action chain '${this.id}' does not contain action with ID '${actionId}', aborting.`);
        }
      } else {
        // this is backwards compatible code
        actionDef = actionId;
        actionId = actionDef.id;
      }

      return ActionRunner.loadActionModule(actionDef.module).then((NewAction) => {
        const actionOutcomes = actionDef.outcomes;

        // create and configure the action
        const actionConfig = ActionRunner.getActionConfig(actionId, this.executionContext);
        const action = new NewAction(actionId, actionDef.label, actionConfig);
        ActionRunner.addHelpersToAction(action, this.context);
        this.addContextToAction(actionDef.module, actionDef, action, availableContexts);

        // Use the unique id of the action to build the unique id of the scope
        const actionParamInstances = ActionChain.createActionParameters(action,
          actionDef.parameters, availableContexts);

        // need to create a separate parameter instance for the debugger/action chain tester since
        // it needs to serialize the parameters which causes expressions in the parameter instance to
        // be evaluated too early because they now behave like constants
        // RESOLVE: We can't move this code into the debugStream because it causes circular require dependency
        const debugActionParamInstances = this.debugStream.isEnabled
          ? ActionChain.createActionParameters(action,
            actionDef.parameters, availableContexts) : null;
        this.log.info('Chain', this.logLabel, 'starting action step', action.logLabel,
          'with parameters:', actionParamInstances, ' setup:', setupTime());

        const mo = new ActionMonitorOptions(actionId, actionDef, action);
        // eslint-disable-next-line arrow-body-style
        return this.log.monitor(mo, (totalStepTime) => {
          // When the current page is exited due to navigation, all the pending actions on that page
          // will get cancelled. Executing an action in such a scenario will result in errors.
          // Instead, we will log a warning and return outcome object with result as undefined.
          if (this.context && this.context.container) {
            if (this.context.container.lifecycleState === Constants.ContainerState.EXITED) {
              this.log.warn('Chain', this.logLabel, 'action', action.logLabel,
                'cannot be executed. Cancelled because the page has exited due to navigation.', totalStepTime());
              // Return object that mocks the outcome of a successful action.
              const oc = {
                name: 'success',
                result: undefined,
              };
              return oc;
            }
          }
          // execute action and process the next action based on the outcome
          return this.debugStream.actionStart(action, debugActionParamInstances)
            .then(() => action.start(actionParamInstances)
              .then((outcome) => {
                const ao = this.handleActionOutcome(actionId, actionOutcomes, outcome);
                this.log.info('Chain', this.logLabel, 'ending action step', action.logLabel,
                  'with outcome', outcome, totalStepTime());

                // debug the end of an action
                this.debugStream.actionEnd(action, outcome);

                if (ao.nextAction) {
                  return this.runActionStep(ao.nextAction, availableContexts);
                }
                return ao.outcome;
              })
              .catch((e) => {
                this.log.error('Chain', this.logLabel, 'action step', action.logLabel, 'failed.', e,
                  totalStepTime(e));
                throw e; // FIXME messaging
              }));
        });
      });
    }

    /**
     * Returns the final outcome if there is no nextAction to execute; Or returns the
     * id of the nextAction.
     * @param {String} actionId
     * @param {Array<String>} actionOutcomes
     * @param {Object} outcomeParam
     * @returns {*} object with one of 2 properties: outcome, a string or nextAction.
     */
    handleActionOutcome(actionId, actionOutcomes, outcomeParam) {
      const outcome = outcomeParam;
      const nextAction = actionOutcomes ? actionOutcomes[outcome.name] : null;

      if (!nextAction) {
        if (this.outcomes && this.outcomes.indexOf(outcome.name) === -1) {
          throw new Error(`The ${outcome.name} outcome does not match one of the chain's possible outcomes.`);
        }

        // automap the outcome result using the return type
        if (this.returnType) {
          const scopeResolver = this.context && this.context.container && this.context.container.scopeResolver;
          const resolvedType = StateUtils.getType(null, { type: this.returnType }, scopeResolver);

          // coerce the result to the return type
          outcome.result = AssignmentHelper.coerceType(outcome.result, resolvedType);
        }

        this.addActionResult(actionId, outcome);
        return { outcome };
      }

      this.addActionResult(actionId, outcome);

      // at this point we need to call the next step in the action
      return { nextAction };
    }

    /**
     * Store the result of the action in results so that we can access it later
     */
    addActionResult(actionId, outcome) {
      if (actionId) {
        this.scope.results.getValue()[actionId] = outcome.result;
      }
    }

    /**
     * Certain actions need more context. Instead of exposing an interface API, we instead look
     * for specific actions and inject the context.
     *
     * The advantage of this approach is that user-specified actions cannot configure their
     * actions to gain access to the context - something we want to avoid in general.
     *
     * @private
     * @param actionType The module name of the action
     * @param actionDef The definition of the action in metadata
     * @param action The newly created action
     */
    addContextToAction(actionType, actionDef, action, availableContexts = this.availableContexts) {
      if (actionType.startsWith(Constants.BUILTIN_ACTION_PATH_PREFIX)) {
        switch (actionType.substr(Constants.BUILTIN_ACTION_PATH_PREFIX.length)) {
          case 'assignVariablesAction':
          case 'callVariableMethodAction':
          case 'resetVariablesAction': {
            action.setAvailableContext(availableContexts);
            break;
          }

          case 'callChainAction': {
            // need to clear action chain specific context before passing it along
            const availableContextsClone = this.availableContexts.clone();
            // leave the $chain on the availableContexts so it can be used to look up extended-typed variables
            // such as ServiceDataProvider
            delete availableContextsClone.$variables;

            action.setContext(availableContextsClone, this.context);
            break;
          }

          case 'forkAction': {
            // backward compatibility, 'originally used overloaded 'outcomes' map
            // now uses a 'parameters.actions' map; keep 'outcomes' for a little while
            // we won't need to do this once we remove backward-compat
            const outcomes = !actionDef.outcomes ? {} : Utils.cloneObject(actionDef.outcomes);
            if (outcomes.join) {
              delete outcomes.join;
            }
            action.setContext(this, outcomes);
            break;
          }
          case 'downloadExtensionsAction':
          case 'forEachAction': {
            action.setContext(this, availableContexts);
            break;
          }

          case 'restAction':
          case 'callModuleFunctionAction':
          case 'callComponentMethodAction':
          case 'fireNotificationEventAction':
          case 'loginAction':
          case 'logoutAction':
          case 'editorUrlAction':
          case 'checkForExtensionsAction':
          case 'getDirtyDataStatusAction':
          case 'resetDirtyDataStatusAction': {
            action.setContext(this.context);
            break;
          }

          case 'takePhotoAction': {
            action.setAvailableContexts(availableContexts);
            break;
          }

          case 'fireCustomEventAction': {
            action.setInternalContext(this.context, this.context.internalContext);
            break;
          }

          default:
            break;
        }
      }

      // inject the container lifecycle state without making the container available to the action
      if (this.context && this.context.container) {
        Object.defineProperty(action, 'containerLifecycleState', {
          get: () => this.context.container.lifecycleState,
        });
      }
    }

    /**
     *
     */
    dispose() {
      delete this.variables;
      this.scope.dispose();
      StoreManager.removeScopeFromStore(this.scope);
      this.scope = null;
    }
  }

  return ActionChain;
});

