'use strict';

define('vb/private/vx/v2/remoteExtensionAdapter',[
  'vb/private/vx/v2/endpointExtensionAdapter',
  'vb/private/utils',
], (EndpointExtensionAdapter, Utils) => {
  /**
   * The extension adapter used to load extension from the extension manager
   */
  class RemoteExtensionAdapter extends EndpointExtensionAdapter {
    /**
     * Gets the extension version
     *
     * @return {String}  The extension version
     */
    static getExtensionVersion() {
      const version = EndpointExtensionAdapter.getExtensionVersion();

      if (!version) {
        // For v2 adds the digest version if defined. Default to 'latest'
        const digestVersion = Utils.cleanString(window.vbInitConfig && window.vbInitConfig.REGISTRY_DIGEST_VERSION);
        return digestVersion ? encodeURIComponent(digestVersion) : 'latest';
      }
      return version;
    }

    /**
     * Return a URIjs uri object initalized with the registry URL
     *
     * @return {Object}
     */
    getUri() {
      const uri = super.getUri();

      const version = this.constructor.getExtensionVersion();
      // The version string has already been encoded, so don't encoded it again in
      // order to preserve the $ character
      uri.segment(version);

      return uri;
    }
  }

  return RemoteExtensionAdapter;
});

