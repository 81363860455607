'use strict';

define('vb/private/stateManagement/packageContainerMixin',[
  'vb/private/utils',
  'vb/private/stateManagement/container',
  'vb/private/constants',
], (Utils, Container, Constants) => {
  /**
   * Mixin that should be used by all package containers.
   *
   * It adds custom resolution of modules imported by package containers.
   */
  const PackageContainerMixin = (superclass) => class extends superclass {
    /**
     * @type {Object}
     */
    get package() {
      return this.parent.package;
    }

    /**
     * Return true if this page can be navigated to from the outside
     * @return {Boolean}
     */
    isNavigable() {
      const { navigation } = this.definition;
      if (navigation && navigation.fromExternal === Constants.FROM_EXTERNAL_NAVIGATION_ENABLED) {
        if (!this.parent.isNavigable()) {
          this.log.error('Navigation to parent', this.parent.getNavPath(), 'is not enabled.');
        }
        return true;
      }
      if (this.isDefault() && this.parent.isNavigable()) {
        return true;
      }

      return false;
    }

    /**
     * Utility method to load a module from package container, and log errors
     *
     * For a component defined in the App UI, this will transform the component path as follow:
     * "app-comp/loader" => "vx/<ext-id>/applications/<app-ui-id>/app-comp/loader"
     *
     * @param  {String} path the import path
     * @return {String}      the adjusted path
     */
    adjustImportPath(path) {
      // vx/ext-id/
      const baseUrl = this.baseUrl;
      // applications/app-id/
      const appUrl = this.package && this.package.path;

      // Skip the JET component because we know they're not local to the App UI
      if (!Container.isJetComponent(path) && baseUrl && appUrl) {
        // need to convert local path to the app based path
        const baseUrlPath = requirejs.toUrl(baseUrl);

        // Here we use RequireJS config that has been set for the package container to manually resolve imported path.
        // In ConfigLoader.addRequirejsPathsFromContainer we setup RJS map so that any request from the container
        // sources uses custom path map before resolving the path to resource URL. Since we are here loading resources
        // from VB code we need to manually mimic that behavior. To do that we copy all entries from the container
        // specific RJS map to the universal "star" map, the one that always apply.
        if (this.package) {
          // get 'resolved' package level RequireJS configuration if we have it
          let localRjs = this.package.localRjs;

          // resolve package level RequireJS configuration
          if (!localRjs) {
            // get RequireJS configuration set for the container
            const containerRjsConfig = this.package.containerRjsConfig || {};
            const {
              containerContextPath,
              map: containerMap = {},
            } = containerRjsConfig;

            if (containerContextPath) {
              let localRjsConfig;

              // Promote package mapping to global in our requirejs object.
              // Promote all maps that can be applied to this context path into the star map.
              // We are taking generic approach even though in reality there are only up to 2 custom paths
              // mapped, one for the extension and one for the container.
              const segments = containerContextPath.split('/');
              if (segments.length) {
                // start should be 'vx/' and we can skip it
                let subPath = segments[0];
                for (let i = 1; i < segments.length; i++) {
                  subPath = `${subPath}/${segments[i]}`;
                  if (containerMap[subPath]) {
                    // RequireJS configuration we will use to resolve paths from the package this container belongs to
                    if (!localRjsConfig) {
                      localRjsConfig = Utils.cloneObject(containerRjsConfig);
                    }
                    const map = localRjsConfig.map || {};

                    // if we found context map that applies here, create new RJS
                    const starMap = map['*'] || (map['*'] = {});
                    const packageMap = containerMap[subPath];
                    Object.keys(packageMap).forEach((mappedPath) => {
                      starMap[mappedPath] = packageMap[mappedPath];
                    });
                  }
                }
              }

              if (localRjsConfig) {
                // setting the context in config object introduces new namespace for the RequireJS
                // which does not interfere/interact with any other namespace, including the default one
                // which we use to load modules.
                localRjsConfig.context = `${baseUrlPath}${containerContextPath}`;
                // get new RequireJs object for resolving the paths
                localRjs = requirejs.config(localRjsConfig);

                // cache the RequireJS ojbect that is local for the package container
                this.package.localRjs = localRjs;
              }
            }
          }

          if (localRjs) {
            const url = localRjs.toUrl(path);
            if (url.startsWith(baseUrlPath)) {
              const resolvedPath = url.substring(baseUrlPath.length);
              return `${baseUrl}${resolvedPath}`;
            }
          }
        }
      }

      return path;
    }
  };

  return PackageContainerMixin;
});

