/* eslint-disable class-methods-use-this,max-classes-per-file */

'use strict';

define('vb/private/events/eventBehaviorFactory',[
  'vb/private/events/eventBehavior',
  'vb/private/events/transformEventBehavior',
  'vb/private/events/checkCancelEventBehavior',
  'vb/private/events/notifyEventBehavior',
  'vb/private/events/notifyWaitEventBehavior',
  'vb/private/events/undeclaredEventBehavior',
  'vb/private/constants',
], (EventBehavior, TransformEventBehavior, CheckCancelEventBehavior,
  NotifyEventBehavior, NotifyWaitEventBehavior, UndeclaredEventBehavior, Constants) => {
  /**
   * factory for various EventBehavior implementations
   */
  class EventBehaviorFactory {
    /**
     * @param name {string}
     * @param model {EventModel}
     * @param registry {EventRegistry}
     * @returns {EventBehavior}
     */
    static create(name, model, registry) {
      const behavior = model && model.behavior;

      switch (behavior) {
        case Constants.EventBehaviors.TRANSFORM:
          return new TransformEventBehavior(name, model, registry);
        case Constants.EventBehaviors.CHECK_CANCEL:
          return new CheckCancelEventBehavior(name, model, registry);
        case Constants.EventBehaviors.NOTIFY_WAIT:
          return new NotifyWaitEventBehavior(name, model, registry);
        case Constants.EventBehaviors.NOTIFY:
          return new NotifyEventBehavior(name, model, registry);
        case Constants.EventBehaviors.DYNAMIC_COMP:
          return new EventBehavior(name, model, registry);
        default:
          if (behavior) {
            throw new Error(`Event declaration has an invalid "behavior": ${behavior}`);
          }
          if (model && model.isDeclared) {
            // this is the default behavior for declared events
            return new NotifyEventBehavior(name, model, registry);
          }
          // this is the behavior for undeclared events
          return new UndeclaredEventBehavior(name, model || {}, registry);
      }
    }
  }

  return EventBehaviorFactory;
});

