'use strict';

define('vbsw/private/plugins/authHeaderHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'urijs/URI'],
  (FetchHandlerPlugin, URI) => {
    // version string can match anything except '/', to allow matrix parameters
    const REGEX = /\/ic\/builder\/(design|deployment|rt)\/[^\/]+\/[^\/]*(\/resources\/(data(mgr)?|vbprocess)|(\/resources)?\/services)(\/.+)?/;

    /**
     * Handler plugin for appending Authorizaton:Session|Public header.
     */
    class AuthHeaderHandlerPlugin extends FetchHandlerPlugin {
      constructor(context, params = {}) {
        super(context, params);

        // the Authorization:Session header will never be added for mobile since this plugin is
        // only installed when isAnonymous is true
        this.authType = params.isAnonymous ? 'Public' : 'Session';
        this.regex = params.regex ? new RegExp(params.regex) : REGEX;
      }

      /**
       * Append the Authorization:Session|Public header.
       *
       * @param request the request to which to append the header
       */
      handleRequestHook(request) {
        // only add the header for url matching the following pattern
        let isMatch = request.url.match(this.regex);

        // in IE11, the path comes through with the service double-dots, and flunks the regex
        if (!isMatch && request.url.indexOf('../') >= 0) {
          const altUrl = new URI(request.url).normalize().toString();
          isMatch = altUrl.match(this.regex);
        }

        if (isMatch) {
          request.headers.set('Authorization', this.authType);
        }

        return Promise.resolve();
      }
    }

    return AuthHeaderHandlerPlugin;
  });


