'use strict';

define('vb/helpers/securityHelpers',[
  'vb/private/configLoader',
  'vb/private/utils',
  'vb/private/services/trapData',
  'vb/private/stateManagement/application',
], (
  ConfigLoader,
  Utils,
  TrapData,
  Application,
) => {
  /**
   *
   */
  class SecurityHelpers {
    /**
     * Navigate to the login page specified by the given path.
     *
     * @param path
     * @returns {Promise}
     */
    /* static navigateToLoginPage(path) {
      return Application.navigateToPage({
        page: path,
      });
    } */

    /**
     * Navigate to the page specified by the given returnPath away from the login page. This call will replace the
     * navigation history so browser back will not navigate you back to the login page.
     *
     * @param returnPath
     * @returns {Promise}
     */
    /* static navigateToReturnPage(returnPath) {
      return Application.navigateToPage({
        page: returnPath,
        history: 'replace',  // make sure browser back won't navigate back to the login page.
      });
    } */

    /**
     * The method can be used to pass information such as username and password collected from the application to
     * the security provider.
     *
     * @param payload an object payload that is understood by the underlying security provider
     * @returns {Promise}
     */
    static securityCallback(payload) {
      return Application.securityProvider.securityCallback(payload);
    }

    /**
     * This method will return an array of plugin urls from the DefaultSecurityProvider that are necessary for
     * FA applications to make REST requests when running in a VB environment. It will return an empty array
     * otherwise.
     *
     * Note that this method should only be called by FA's own implementation of the security provider.
     *
     * @returns {Promise<Array>}
     */
    static getDefaultServiceWorkerPluginsForVbEnvironment() {
      return Promise.resolve().then(() => {
        const vbConfig = window.vbInitConfig || {};
        const vbServer = vbConfig.VB_SERVER;

        // determine if we are running in a VB environment by checking if the VB_SERVER property
        // is injected into vbInitConfig
        if (vbServer) {
          // dynamically load the defaultSecurityProvider in order to get the list of default plugins
          return Utils.getResource('vb/private/types/defaultSecurityProvider')
            .then((DefaultSecurityProviderClass) => {
              const securityProvider = Application.securityProvider;

              // get the security provider from the application if exist in order to get to its
              // config object
              if (securityProvider) {
                const defaultSecurityProvider = new DefaultSecurityProviderClass();

                // directly set the idcsInfo instead of calling initialize
                const config = securityProvider.config || {};
                defaultSecurityProvider.idcsInfo = config.idcsInfo || {};

                return defaultSecurityProvider.getServiceWorkerPlugins(
                  config, !securityProvider.userInfo.isAuthenticated);
              }

              return [];
            });
        }

        return [];
      });
    }

    /**
     * Get an access token for the given scope using the implicit flow grant type. A null
     * token will be returned if there is missing info necessary for requesting the token.
     *
     * @param scope allowed scope for the access token
     * @returns {Promise<T>}
     */
    static getImplicitFlowAccessToken(scope) {
      return Promise.resolve().then(() => {
        const { securityProvider } = Application;

        // make it a noop if idcsInfo is not available which can happen in DT or if the
        // app is not configured for implicit flow
        // NOTE: The check is done here to avoid changes to the internal api.
        const { idcsInfo } = securityProvider;
        if (!idcsInfo || !idcsInfo.hostName) {
          return null;
        }

        if (typeof securityProvider.vbRefreshImplicitFlowAccessToken === 'function') {
          return securityProvider.vbRefreshImplicitFlowAccessToken(scope);
        }

        throw new Error('Unsupported method for the current security provider');
      });
    }

    /**
     * Return a list of allowed scopes that are configured for implicit flow.
     *
     * @returns {[]}
     */
    static getImplicitFlowAllowedScopes() {
      const { securityProvider } = Application;
      const { idcsInfo } = securityProvider;

      return idcsInfo && idcsInfo.allowedScopes ? idcsInfo.allowedScopes : [];
    }

    /**
     * Returns value of the access token from the service's token relay endpoint.
     *
     * @param {Object} [options]
     * @param {string} options.serviceName Name of the service token relay endpoint is requested for
     * @param {string} [options.extensionId = 'base'] Id of the extension the service is defined in.
     * @param {string} [options.extensionVersion = '1.0'] Version of the extension the service is defined in.
     * @param {string} [options.activeProfile = ConfigLoader.activeProfile]
     * @returns {Promise<string>}
     */
    static getTrapServiceAccessToken(serviceName, options = {
      activeProfile: ConfigLoader.activeProfile,
      serviceName,
    }) {
      return Promise.resolve().then(() => {
        if (!serviceName) {
          throw new Error('Required service name argument missing.');
        }
        const mergeOptions = Utils.mergeObject({
          activeProfile: ConfigLoader.activeProfile,
          serviceName,
        }, options);
        const trapData = TrapData.getTrapData();

        const tokenRelayUrl = trapData.getTokenRelayUrl(mergeOptions);
        const isTrap2 = trapData.shouldUseTrapService();

        const trapRequest = Utils.createTokenRelayRequest(tokenRelayUrl, isTrap2);
        return window.fetch(trapRequest).then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw new Error(response.statusText);
        }).then((token) => {
          if (isTrap2) {
            return token.access_token;
          }
          // strip starting 'Bearer' prefix and return pure value
          return token.authenticationHeader.replace(/^(Bearer)/, '').trim();
        });
      });
    }
  }

  return SecurityHelpers;
});

