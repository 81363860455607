'use strict';

define('vb/private/vx/v2/localExtensionAdapter',[
  'vb/private/vx/baseExtensionAdapter',
  'vb/private/utils',
], (BaseExtensionAdapter, Utils) => {
  /**
   * The extension adapter used to load extension from local file
   */
  class LocalExtensionAdapter extends BaseExtensionAdapter {
    /**
     * For the local extension adapter, this function consist of loading the .json files mocking
     * the extension manager registry.
     * @return {Promise}
     */
    initiateFetchManifest() {
      return Promise.resolve().then(() => {
        this.log.info('Loading extensions from local file', this.registryUrl,
          'using extension manager v2.');

        return Utils.loadAndParse(this.registryUrl).then((registry) => {
          const reg = registry.extensions || [];
          const ref = {};
          const promises = [];

          // Reference the ui part and build the promises
          reg.forEach((ext) => {
            ref[ext.id] = ext;
            promises.push(Utils.loadAndParse(`${ext.baseUrl}/manifest.json`));
          });

          // Load all the vb-extension.json and insert the missing ui.baseUrl
          return Promise.all(promises).then((extensions) => {
            const requirejsInfo = [];
            let appUiInfo;
            let pwaInfo;

            extensions.forEach((ext) => {
              // Insert the baseUrl part in the extension
              ext.baseUrl = ref[ext.id].baseUrl;
              // Convert the dependencies
              if (ext.dependencies) {
                const dependencies = Utils.cloneObject(ext.dependencies);
                ext.dependencies = {};
                dependencies.forEach((dep) => {
                  ext.dependencies[dep.id] = dep.version;
                });
              }

              if (ext['app-ui-info']) {
                appUiInfo = appUiInfo || [];
                appUiInfo.push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext['app-ui-info'].metadata,
                });
              }

              if (ext['requirejs-info']) {
                requirejsInfo.push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext['requirejs-info'].metadata,
                });
              }

              if (ext['pwa-info']) {
                pwaInfo = pwaInfo || [];
                pwaInfo.push({
                  id: ext.id,
                  version: ext.version,
                  metadata: ext['pwa-info'].metadata,
                });
              }
            });

            // eslint-disable-next-line object-curly-newline
            return { extensions, appUiInfo, requirejsInfo, pwaInfo };
          });
        });
      });
    }
  }

  return LocalExtensionAdapter;
});

