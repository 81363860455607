'use strict';

define('vb/private/types/capabilities/fetchByKeysIteration',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/types/dataProviderConstants',
  'vb/private/utils',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeysUtils',
  'vb/private/types/capabilities/fetchFirst',
  'vbc/private/monitorOptions'],
(Log, Constants, DPConstants, Utils, DataProviderUtils, FetchContext, FetchByKeysUtils, FetchFirst, MonitorOptions) => {
  const FETCH_BY_KEYS_CAPABILITY = 'fetchByKeys';

  /**
   * fetchByKeys data provider capability types supported
   */
  const FetchByKeysCapability = {
    FETCH_BY_KEYS_ITERATION: 'iteration',
  };

  /**
   * Used when pagingCriteria.maxSize is not set.
   * @type {number}
   */
  const DEFAULT_MAX_SIZE = 1000;

  /**
   * Implements the iteration based FetchByKeys implementation. This uses the fetchFirst iteration
   * based implementation but where the SDP is optimized for 'fast' lookups, by fetching
   * more rows for every iteration, so key lookups are faster.
   */
  class FetchByKeysIteration extends FetchFirst {
    /**
     * constructor
     *
     * @param sdp
     * @param params {Object} see oj.FetchByKeysParameters. Contains the following properties
     *  - keys: Set<any> keys of the rows to fetch
     */

    /**
     *  prune options to just what supported on oj.FetchListParameters; also when callers
     *  provides more than one key in via 'keys' parameter use just the first.
     *
     * @param {object=} options Options to control fetch
     * @property {number} options.keys Set of keys to fetch
     * @return {Promise} Promise object resolves to a compound object which contains an array of
     * row data objects, an array of ids, and the startIndex triggering done when complete.<p>
     *
     * @param options
     */
    whiteListFetchOptions(options) {
      return FetchByKeysUtils.whiteListFetchOptions(this.sdp, options);
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this
    getFetchCapability() {
      return DPConstants.CapabilityType.FETCH_BY_KEYS;
    }

    /**
     * get the size for the paginate transform options. Because this is an optimized iteration
     * based implementation over fetchFirst the size is determined differently than super:
     * - if size is not provided by fetch call then the maxSize configured on the SDP variable is
     *   used. For external fetches this can be undefined until a later time when this same
     *   method is called from reconcileTransformOptions, to reconcile options set in RestAction
     *   for instance with what this capability determined from fetch call.
     * - if size = -1, then same as above.
     * - if size >= 0, then super is called.
     *
     * @returns {*} size
     */
    getPaginateOptionsSize() {
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;
      let variablePCMaxSize;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCMaxSize = variablePC.maxSize || DEFAULT_MAX_SIZE;
      }
      let size;

      if (fetchOpts.size === undefined || fetchOpts.size === null || fetchOpts.size === -1) {
        // because this is an optimized iteration based implementation over fetchFirst the
        // maxSize set on the variable gets used. But this can still be undefined.
        size = variablePCMaxSize;
      } else {
        size = super.getPaginateOptionsSize();
      }

      return size;
    }

    /**
     * Because this is an optimized iteration over fetchFirst, the size is determined differently
     * than super:
     * - if size is not set on paginateOptions then the maxSize configured on the Rest options are
     *   used, otherwise the default maxSize. size is ignored.
     *   TODO: reviewers should size be used at all, so maxSize, followed by size, followed by
     *   defaultMaxSize?
     * - if size = -1, then same as above.
     * - if size >= 0, then super is called.
     * if present. Returns a valid 0 or positive number if size was changed. Otherwise undefined.
     *
     * @param paginateOptions
     * @param restPaginateOptions
     * @returns {*} changed size or undefined if there is no change
     */
    // eslint-disable-next-line class-methods-use-this
    reconcilePaginateOptionsSize(paginateOptions, restPaginateOptions) {
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      if ((!tpo.size && tpo.size !== 0) || (tpo && tpo.size === -1 && rtpo.maxSize)) {
        changed = (rtpo && rtpo.maxSize && rtpo.maxSize >= 0) ? rtpo.maxSize : DEFAULT_MAX_SIZE;
      }

      return changed;
    }

    /**
     * overridden so multiple iterations can be made to locate the keys items and and the
     * response packed in a form FetchByKeys contract expects.
     * @returns {Promise}
     */
    fetch() {
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const cap = this.sdp.getCapability(FETCH_BY_KEYS_CAPABILITY);

      const isImplementationIteration = function () {
        return cap && cap.implementation === FetchByKeysCapability.FETCH_BY_KEYS_ITERATION;
      };

      if (isImplementationIteration()) {
        return Promise.resolve().then(() => {
          const { sdp } = this;
          const { fetchOptions } = this;

          sdp.log.startFetch('ServiceDataProvider', uniqueId, 'fetchByKeys called with options', fetchOptions,
            'and state :', this.sdpState);
          const mo = new MonitorOptions(MonitorOptions.SPAN_NAMES.SDP_FETCH_BY_KEYS_ITERATION, uniqueId);
          return sdp.log.monitor(mo, (fetchMonitor) => {
            const fetchAsyncIterator = super.fetch()[Symbol.asyncIterator]();

            return this.fetchKeysByIteration(fetchAsyncIterator).then((resultMap) => {
              const mappedResultMap = new Map();
              resultMap.forEach((value, key) => {
                const mappedItem = [value];
                mappedResultMap.set(key, mappedItem[0]);
              });
              const fetchByKeysResult = {
                fetchParameters: this.getFetchOptionsForResponse(),
                results: mappedResultMap,
              };

              sdp.log.endFetch('ServiceDataProvider', uniqueId,
                'fetchByKeys using iteration succeeded with result:', fetchByKeysResult, fetchMonitor());

              return (fetchByKeysResult);
            }).catch((err) => {
              sdp.log.endFetch('ServiceDataProvider', uniqueId,
                'fetchByKeys using iteration failed with error:', err, fetchMonitor(err));

              // super class would have fired a notification event already. no need to repeat here.
              throw (err);
            });
          });
        });
      }

      // we should never get here because SDP will never call this class for iteration based
      // lookups
      const err = `ServiceDataProvider ${uniqueId}: FetchByKeys lookup based implementation `
        + 'cannot be used for iteration based implementation! Check your configuration!';
      this.log.error(err);
      return Promise.reject(err);
    }

    /**
     * Calls next() until all the requested keys are all found, or when we are done iterating.
     * @private
     */
    fetchKeysByIteration(asyncIterator, rm, fetched) {
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const resultMap = rm || new Map();
      let rowsFetched = fetched || 0;
      let limit;
      const { fetchOptions } = this;
      let hasEmptyKeys = false;
      const fetchKeys = fetchOptions && fetchOptions.keys;

      // log a warning when key requested is empty
      if (fetchKeys && fetchKeys instanceof Set) {
        hasEmptyKeys = fetchKeys.has('') || fetchKeys.has(null) || fetchKeys.has(undefined);
        if (hasEmptyKeys) {
          this.log.warn('ServiceDataProvider', uniqueId, ': fetchByKeys using iteration called with empty keys',
            fetchKeys, '. Check to make sure that this is a valid key that returns data!');
        }
      }

      return asyncIterator.next().then((iteratorResult) => {
        let foundAllKeys = true;
        limit = limit || this.getIterationLimit();
        const { value } = iteratorResult;
        const { data } = value;
        const { metadata } = value;
        const keys = metadata.map(function (met) {
          return met.key;
        });

        fetchKeys.forEach((findKey) => {
          if (!resultMap.has(findKey)) {
            keys.forEach((key, index) => {
              const idAttr = this.sdpState.value[this.sdp.getIdAttributeProperty()];
              const idHelper = DataProviderUtils.getIdAttributeHelper(idAttr);

              // equals check between keys does not work since we are dealing with 2 different instances of keys here.
              // The caller provides 'findKey' instance, quite likely to be the same one provided in a previous
              // fetchFirst response. Whereas 'key' is one we construct from response for fetchByKeys() call.
              // Because SDP does not cache its data we don't hold on to previously constructed key instances so we
              // have to use a smarter comparison of keys.
              if (idHelper.compare(key, findKey)) {
                // use the key instance provided via fetchOptions as the key in the result and also update the metadata
                // to use the same key instance.
                const itemMetadata = metadata[index];
                itemMetadata.fixupKey(findKey);
                resultMap.set(findKey, { metadata: itemMetadata, data: data[index] });
              }
            });
          }

          if (!resultMap.has(findKey)) {
            foundAllKeys = false;
          }
        });

        // Keep track of how many rows we have fetched
        rowsFetched += data.length;

        // Keep iterating if we haven't found all keys and there are more data
        if (!foundAllKeys && !iteratorResult.done) {
          if (limit !== -1 && rowsFetched >= limit) {
            // we have reached the limit, just return the results
            return resultMap;
          }
          return this.fetchKeysByIteration(asyncIterator, resultMap, rowsFetched);
        }
        return resultMap;
      });
    }
  }

  return FetchByKeysIteration;
});

