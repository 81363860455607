'use strict';

define('vb/private/translations/bundlesModel',[
  'ojs/ojconfig',
  'vb/private/configuration',
  'vb/private/constants',
  'vb/private/log',
  'vb/private/translations/bundleDefinition',
  'vb/private/translations/bundleUtils',
  'vb/private/translations/bundleV2Definition',
], (
  ojConfig,
  Configuration,
  Constants,
  Log,
  BundleDefinition,
  BundleUtils,
  BundleV2Definition,
) => {
  const logger = Log.getLogger('vb/private/translations/bundlesModel');

  const PROXY_BUNDLES_STORAGE_ID = 'vbcs.translations.proxyBundles';
  const APPLICATION_PROXY_BUNDLES_STORAGE_ID = `${PROXY_BUNDLES_STORAGE_ID}.${Configuration.applicationUrl}`;

  function isProxyBundles(options) {
    // This BundlesModel explicitly indicates whether or not to proxy
    if ((typeof options.proxyBundles) !== 'undefined') {
      return !!options.proxyBundles;
    }

    // Overridden in localStorage?
    let proxyBundlesStr = BundlesModel.storageInterface.getItem(PROXY_BUNDLES_STORAGE_ID);
    if (proxyBundlesStr !== null) {
      return proxyBundlesStr === 'true';
    }

    proxyBundlesStr = BundlesModel.storageInterface.getItem(APPLICATION_PROXY_BUNDLES_STORAGE_ID);
    if (proxyBundlesStr !== null) {
      return proxyBundlesStr === 'true';
    }

    // Overridden in vbInitConfig?
    const initConfigProxyBundles = globalThis.vbInitConfig &&
      globalThis.vbInitConfig.translations &&
      globalThis.vbInitConfig.translations.proxyBundles;
    if ((typeof initConfigProxyBundles) !== 'undefined') {
      return !!initConfigProxyBundles;
    }

    // Default to true if not specified
    return true;
  }

  /**
   *
   */
  class BundlesModel {
    /**
     * Create a model for BundleDefinitions.
     * Falsy paths should be ignored/skipped (VBS-2097)
     *
     * @param runtimeEnv RuntimeEnvironment
     * @param [referencePath] {string} the directory of the referring container
     * @param [options] {Object}
     * @param [options.initParams] {Object}
     * @param [options.replacementValues] {Object} map of key/value pairs for expressions in path
     * @param [options.allowSelfRelative] {boolean} (V1) let the container JSON use "./" to mean 'relative to the same folder
     * @param [options.isUnrestrictedRelative] {boolean} (V1) you can reach outside of your current folder (only app-flow)
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     * @param [extension] {object} the extension containing this BundlesModel (or null for 'base')
     * @private
     */
    constructor(runtimeEnv, referencePath = '', options = {}, extension = null) {
      this.log = logger;
      this.relativePath = referencePath;
      this.runtimeEnvironment = runtimeEnv;
      this.options = options;

      this.options.proxyBundles = isProxyBundles(options);

      this.extensionId = (extension && extension.id) || Constants.ExtensionFolders.BASE;

      this.bundleDefinitions = {};
      this.bundleV2Definitions = {};
      this.extensionsBundleV2Definitions = {};

      this.loadV1Promises = null;
      this.loadV2Promises = null;
    }

    /**
     * @private
     */
    addBundleV1Definitions(declarations) {
      // using JET to get locale
      const locale = ojConfig.getLocale();
      Object.entries(declarations)
        .forEach(([name, decl]) => {
          const path = BundleUtils.evaluateTranslationPath(decl, this.options.initParams);
          if (!path) {
            logger.error(`No path declared for translation bundle ${name}`);
          } else if (BundleUtils.isV2(path)) {
            logger.error(`Not a v1 translation bundle ${name}`);
          } else {
            // eslint-disable-next-line max-len
            const bundleDefinition = new BundleDefinition(name, path, decl, locale, this.relativePath, this.options);
            this.bundleDefinitions[name] = bundleDefinition;

            // Initiate load of this BundleDefinition, but don't block on it
            if (bundleDefinition.isAllowed()) {
              bundleDefinition.load(this.runtimeEnvironment);
            }
          }
        });
    }

    /**
     * @param {string} extId Id of the extension containing the bundle declarations (may be 'self')
     * @param {Array<string>} bundleNames Names of string bundles for this BundlesModel (the key in the $translations map)
     * @param {Promise<ExtensionTranslationsConfig>} extBundleDeclarations All Bundle Declarations for extId <extension/translations>
     * @private
     */
    addBundleV2Definitions(extId, bundleNames, extBundleDeclarations) {
      bundleNames.forEach((bundleName) => {
        // eslint-disable-next-line max-len
        const bundleV2Definition = BundleV2Definition.getBundleV2Definition(BundlesModel.application, this.options, (extId === 'self') ? this.extensionId : extId, bundleName, extBundleDeclarations);

        if (extId === 'self') {
          this.bundleV2Definitions[bundleName] = bundleV2Definition;
        } else {
          if (!this.extensionsBundleV2Definitions[extId]) {
            this.extensionsBundleV2Definitions[extId] = {};
          }
          this.extensionsBundleV2Definitions[extId][bundleName] = bundleV2Definition;
        }

        // Initiate load of this BundleV2Definition, but don't block on it
        bundleV2Definition.load(this.runtimeEnvironment);
      });
    }

    /**
     * load all bundles, and make a map of name - to - string map
     * @param {boolean} [force] Ensure all bundles are fully loaded, even if they've been proxied
     * @returns {Promise<BundlesModel>}
     */
    load(force) {
      // Load the bundleV1Definitions, make a 'master' map of bundle name -> bundle strings
      this.loadV1Promises = this.loadV1Promises ||
        Object.values(this.bundleDefinitions)
          .filter((def) => def.isAllowed())
          .map((def) => def.load(this.runtimeEnvironment));

      // Load the bundleV2Definitions, add to the 'master' map of bundle name -> bundle strings
      if (!this.loadV2Promises) {
        this.loadV2Promises = Object.values(this.bundleV2Definitions)
          .map((def) => def.load(this.runtimeEnvironment));

        // Load the extensionsBundleV2Definitions, make a 'master' map of extensionId -> (bundle name -> bundle strings)
        Object.entries(this.extensionsBundleV2Definitions)
          .forEach(([extId, defs]) => {
            this.loadV2Promises.push(...Object.values(defs)
              .map((def) => def.load(this.runtimeEnvironment)));
          });
      }

      const promises = [];

      // If proxying the bundles, don't wait for the load.  The map of bundle name -> bundle strings
      // is initialized to the proxied bundles in addBundleV1Definitions & addBundleV2Definitions
      if (force || !this.options.proxyBundles) {
        if (this.loadV1Promises.length) {
          promises.push(...this.loadV1Promises);
        }

        if (this.loadV2Promises.length) {
          promises.push(...this.loadV2Promises);
        }

        return Promise.all(promises)
          .then(() => {
            return this;
          });
      }

      return Promise.resolve(this);
    }

    /**
     * Get the (V1) Bundle strings map.  Fills bundleMap with the V1 Bundles String Maps.
     * @param bundleMap {Object.<string, Object>}  Map of bundleName to Strings Map
     * @returns {Object.<string, Object>} Map of bundleName to Strings Map
     */
    getStringMap(bundleMap = {}) {
      // For each V1 Bundle, ask it to add its named bundle map to the string map
      Object.values(this.bundleDefinitions)
        .filter((def) => def.isAllowed())
        .forEach((def) => {
          def.addStringMapToBundleMap(bundleMap);
        });
      return bundleMap;
    }

    /**
     * Get the (V1) BundleDefinition with name
     * @param name {string} name of the BundleDefinition
     * @returns {BundleDefinition}
     */
    getBundleDefinition(name) {
      return this.bundleDefinitions[name];
    }

    /**
     * Get the (V2) Bundle strings map.  Fills bundleMap with the V2 Bundles String Maps.
     * @param bundleMap {Object.<string, Object>}  Map of bundleName to Strings Map
     * @returns {Object.<string, Object>} Map of bundleName to Strings Map
     */
    getV2StringMap(bundleMap = {}) {
      // For each V2 Bundle, ask it to add its named bundle map to the string map
      Object.values(this.bundleV2Definitions)
        .forEach((def) => {
          def.addStringMapToBundleMap(bundleMap);
        });
      return bundleMap;
    }

    /**
     * Get the (V2) Bundle strings maps for all extensions
     * @returns {Object.<string, Object.<string, Object>>} Map of extensionId to Map of bundleName to Strings Map
     */
    getExtensionsV2StringMaps() {
      return Object.fromEntries(Object.keys(this.extensionsBundleV2Definitions)
        .map((extId) => [extId, this.getExtensionV2StringMap(extId)]));
    }

    /**
     * Get the (V2) Bundle strings map for the extension
     * @param extId {string}
     * @param bundleMap {Object.<string, Object>}  Map of bundleName to Strings Map
     * @returns {Object.<string, Object>} Map of bundleName to Strings Map
     */
    getExtensionV2StringMap(extId, bundleMap = {}) {
      const extensionBundleV2Definitions = this.extensionsBundleV2Definitions[extId];
      if (!extensionBundleV2Definitions) {
        return bundleMap;
      }

      // For each V2 Extension Bundle, ask it to add its named bundle map to the string map
      Object.values(extensionBundleV2Definitions)
        .forEach((def) => {
          def.addStringMapToBundleMap(bundleMap);
        });
      return bundleMap;
    }

    /**
     * Load an array of (non-null) BundleDefinition.
     * Falsy paths should be ignored/skipped (VBS-2097)
     * The bundlesModel is created, and load is initiated.
     *
     * @param runtimeEnv RuntimeEnvironment
     * @param [metadata] {Object} the "translations" or "imports" : "translations" configuration metadata
     * @param [referencePath] {string} the directory of the referring container
     * @param [options] {Object}
     * @param [options.replacementValues] {Object} map of key/value pairs for expressions in path
     * @param [options.allowSelfRelative] {boolean} (V1) let the container JSON use "./" to mean 'relative to the same folder
     * @param [options.isUnrestrictedRelative] {boolean} (V1) you can reach outside of your current folder (only app-flow)
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     * @param [extension] {object} the extension containing this BundlesModel (or null for 'base')
     * @returns {BundlesModel}
     */
    static createBundlesModel(runtimeEnv, metadata = {}, referencePath = '', options = {}, extension = null) {
      const bundlesModel = new BundlesModel(runtimeEnv, referencePath, options, extension);

      // Has V1 translations declaration
      // "translations": {
      //   "<bundleName>": {
      //     "path": "<bundlePath>"
      //   }
      // }
      if (metadata.translations) {
        bundlesModel.addBundleV1Definitions(metadata.translations);
      }

      // Has V2 translations declaration
      // "imports": {
      //   "translations": {
      //     "self": [<bundleName>,...],
      //     "<extId>": [<bundleName>,...],
      //     "base": [<bundleName>,...]
      //   }
      // }
      //
      // Assemble the corresponding bundle name/path information from the translation-config declaration
      // "translations": {
      //   "<bundleName>": {
      //     "path": "<bundlePath>"
      //   }
      // }
      if (metadata.imports && metadata.imports.translations) {
        const selfId = extension ? extension.id : Constants.ExtensionFolders.BASE;
        const translations = metadata.imports.translations;

        // Get list of extension ids that have bundleNames that we are interested in.
        // Map self to this extension id
        const extIds = Object.keys(translations)
          .map((extId) => ((extId === 'self') ? selfId : extId));

        // Get the bundle paths for each extension we are interested in (including this extension)
        const extsBundleDeclarations = BundlesModel.getExtensionsBundleDeclarations(runtimeEnv, extIds);

        // remap selfId declarations to 'self'
        const selfBundleDeclaration = extsBundleDeclarations[selfId];
        if (selfBundleDeclaration) {
          delete extsBundleDeclarations[selfId];
          extsBundleDeclarations.self = selfBundleDeclaration;
        }

        // Get the bundle declarations for the extensions and add them to the bundle model
        // We extract just the bundle declarations listed in imports.translations.  There could be more bundle
        // declarations in an extension, but aren't used by this BundlesModel
        Object.entries(translations)
          .forEach(([extId, bundleNames]) => {
            bundlesModel.addBundleV2Definitions(extId, bundleNames, extsBundleDeclarations[extId]);
          });
      }

      // Initiate the load of the BundlesModel, but don't block on it
      bundlesModel.load();

      return bundlesModel;
    }

    /**
     * Load an array of (non-null) BundleDefinition.
     * Falsy paths should be ignored/skipped (VBS-2097)
     *
     * @param runtimeEnv RuntimeEnvironment
     * @param [metadata] {Object} the "translations" or "imports" : "translations" configuration metadata
     * @param [referencePath] {string} the directory of the referring container
     * @param [options] {Object}
     * @param [options.replacementValues] {Object} map of key/value pairs for expressions in path
     * @param [options.allowSelfRelative] {boolean} (V1) let the container JSON use "./" to mean 'relative to the same folder
     * @param [options.isUnrestrictedRelative] {boolean} (V1) you can reach outside of your current folder (only app-flow)
     * @param [options.proxyBundles] {boolean} Make proxies for bundles and don't wait for the bundles to be loaded
     * @param [extension] {object} the extension containing this BundlesModel (or null for 'base')
     * @returns {Promise<BundlesModel>}
     */
    static loadBundlesModel(runtimeEnv, metadata = {}, referencePath = '', options = {}, extension = null) {
      const bundlesModel = BundlesModel.createBundlesModel(runtimeEnv, metadata, referencePath, options, extension);

      return bundlesModel.load();
    }

    /**
     * Load the bundles name/declaration information from the translations-config in the extensions (including 'base')
     *
     * @param {Object} runtimeEnv
     * @param {Array<string>} extIds array of extension id or 'base'
     * @return {Object.<string, Promise<ExtensionTranslationsConfig>>} Map of extensionId to extension bundles Promise
     * @private
     */
    static getExtensionsBundleDeclarations(runtimeEnv, extIds) {
      /** @type {Object.<string, Promise<ExtensionTranslationsConfig>>} */
      const bundleDeclarations = {};

      extIds.forEach((extId) => {
        // See if we've already located the bundles declaration for each extension (and 'base').
        // If not, load the translations configuration
        let extensionBundleDeclarations = BundlesModel.extensionsBundleDeclarations[extId];
        if (!extensionBundleDeclarations) {
          let translationsConfig;
          let extension = null;
          if (extId === Constants.ExtensionFolders.BASE) {
            // Load the translations-config for the base app
            translationsConfig = runtimeEnv.getTextResource('resources/translations/translations-config.json');
          } else {
            // Load the translations-config for the extension
            translationsConfig = BundlesModel.getExtensions()
              .then((extensions) => {
                extension = extensions[extId];
                // Make sure the extension is initialized, so its require mappings are set.
                return extension.init()
                  // eslint-disable-next-line max-len
                  .then(() => runtimeEnv.getExtensionTextResource(`${extension.baseUrl}translations/translations-config.json`));
              });
          }

          // Extract the translations map of bundleName:bundlePath from the translations-config
          // "translations": {
          //   "<bundleName>": {
          //     "path": "<bundlePath>"
          //   }
          // }
          extensionBundleDeclarations = translationsConfig
            .then((config) => ({
              translations: JSON.parse(config).translations,
              extension,
            }))
            .catch((err) => {
              logger.error(`Failed to load translations-config for ${extId}`, err);
              return { translations: [] };
            });

          BundlesModel.extensionsBundleDeclarations[extId] = extensionBundleDeclarations;
        }

        bundleDeclarations[extId] = extensionBundleDeclarations;
      });

      return bundleDeclarations;
    }

    /**
     * Get a Promise to a map of extensions that have translations/translations-config.json
     * @returns {Promise<Map<string, Object>>}
     */
    static getExtensions() {
      if (!BundlesModel.extensions) {
        BundlesModel.extensions = BundlesModel.application.extensionRegistry.getTranslations();
      }
      return BundlesModel.extensions;
    }

    /**
     * Test helper
     * Reset cached information
     */
    static reset() {
      BundlesModel.application = null;
      BundlesModel.extensionsBundleDeclarations = {};
      BundlesModel.extensions = null;
      BundlesModel.storageInterface = globalThis.localStorage;
      BundleV2Definition.reset();
    }
  }

  /**
   * Reference to Application, so all Bundles can have access.
   * This is assigned in Application.load() (vb/private/stateManagement/applicationClass.js)
   */
  BundlesModel.application = null;

  /**
   * Map of extensionId to Map of bundle name/declaration
   * @type {Object.<string, Promise<ExtensionTranslationsConfig>>} Map of extensionId to Map of bundle name/declaration
   */
  BundlesModel.extensionsBundleDeclarations = {};

  /**
   * Map of extensionId to extension that contains translation-config.json
   * @type {Promise<Map<string, object>>}
   */
  BundlesModel.extensions = null;

  /**
   * Storage for option overrides.
   * Having it defined as a property allows tests to inject a mock storage
   */
  BundlesModel.storageInterface = globalThis.localStorage;

  BundlesModel.PROXY_BUNDLES_STORAGE_ID = PROXY_BUNDLES_STORAGE_ID;

  return BundlesModel;
});

