'use strict';

define('vb/private/stateManagement/bridgeFactory',[
  'vb/private/stateManagement/fragmentBridge',
  'vb/private/stateManagement/switcherBridge',
], (FragmentBridge, SwitcherBridge) => {
  /**
   *
   */
  class BridgeFactory {
    constructor(container) {
      this.create = (type, props) => {
        switch (type) {
          case 'fragment': {
            return FragmentBridge.getOrCreateBridge(container, props);
          }

          case 'switcher': {
            return new SwitcherBridge(container, props);
          }

          case 'fragment-slot': {
            return FragmentBridge.getOrCreateBridge(container, Object.assign({ type: 'fragment-slot' }, props));
          }

          default:
            throw new Error('Missing or invalid bridge type');
        }
      };
    }
  }

  return BridgeFactory;
});

