/* eslint max-classes-per-file: ["error", 2] */

'use strict';

define('vb/private/utils',[
  'knockout',
  'vb/private/constants',
  'vb/errors/httpError',
  'jsondiff',
  'vbc/private/utils',
  'vb/private/utils/cssUrlReplacer',
  'urijs/URI',
  'vb/private/utils/intersectionObserverUtils',
], (ko, Constants, HttpError, JsonDiff, CommonUtils, CssUrlReplacer, URI, IntersectionObserverUtils) => {
  let logger;

  // Because of circular dependency we need to load the logger on demand
  const getLogger = () => {
    if (!logger) {
      const Log = requirejs('vb/private/log');
      logger = Log.getLogger('/vb/private/utils');
    }

    return logger;
  };

  // Use jsonDiff to compare state object
  const jsonDiff = JsonDiff.create({
    arrays: {
      detectMove: false,
    },
    cloneDiffValues: false,
  });

  class NameFilters {
    static isNotDecorator(name) {
      return name && !name.startsWith(Constants.Decorators.PREFIX);
    }
  }
  class Utils extends CommonUtils {
    /**
     * Return true if the application is a host application (aka unified application, v2)
     * @return {boolean}
     */
    static isHostApplication(vbInitConfig = window.vbInitConfig) {
      return vbInitConfig && vbInitConfig.APP_TYPE === 'unified';
    }

    /**
     * Replace multiple forward slashes with a single slash (except after ':'), and remove ending slash
     * unless keepEndingSlash parameter is set to true.
     * @param {string} url
     * @param {boolean} [keepEndingSlash=false]
     * @returns {string}
     */
    static cleanUpExtraSlashes(url, keepEndingSlash = false) {
      const noDuubleSlashes = url.replace(/([^:]\/)\/+/g, '$1');
      return keepEndingSlash ? noDuubleSlashes : noDuubleSlashes.replace(/\/+$/, '');
    }

    /**
     * Determine if the path is an absolute URI, which means it has a host name.
     * @param {string} urlPath
     * @returns {boolean}
     */
    static isAbsoluteUrl(urlPath) {
      return urlPath && new URI(urlPath).is('absolute');
    }

    /**
     * Returns true if path is absolute on the server (starting with '/') or is and absolute URL.
     * @param {string} path
     * @returns {boolean}
     */
    static isAbsolutePath(path = '') {
      return path[0] === '/' || Utils.isAbsoluteUrl(path);
    }

    /**
     * Return a promise to load a resource.
     * Reject with the error if there was an error or the file doesn't exist.
     *
     * @param  {String} resource the path to the resource to load
     * @return {Promise} a promise resolving to the content of the resource
     */
    static getResource(resource) {
      return new Promise((resolve, reject) => {
        requirejs([resource],
          (loaded) => {
            resolve(loaded);
          },
          (reason) => {
            let error;
            // For HTTP error, the requireType property is undefined
            if (reason.requireType) {
              error = reason;
            } else {
              error = new HttpError(reason.xhr.status, reason);
            }

            reject(error);
          });
      });
    }

    /**
     * Return a promise to load an array of resources.
     * Reject with error if there was an error or the resources don't exist.
     *
     * @param {Array<String>} resources an array of paths to the resources to load
     * @returns {Promise} a promise to return an array of loaded resources
     */
    static getResources(resources) {
      return new Promise((resolve, reject) => {
        requirejs(resources,
          (...loaded) => {
            resolve(loaded);
          },
          (reason) => {
            if (reason.xhr && reason.xhr.status) {
              reject(new HttpError(reason.xhr.status, reason));
            } else {
              reject(reason);
            }
          });
      });
    }

    /**
     * Check if a resource exists at the given path.
     * @param  {String} resource the path to the resource to check
     * @return {Promise}         a Promise that resolves to true if the file exist
     */
    static resourceExists(resource) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        const res = `${require.toUrl('')}${resource.substr(5)}`;

        xhr.open('HEAD', res);
        xhr.onload = function () {
          resolve(this.status !== 404);
        };
        xhr.onerror = function () {
          reject({ status: this.status, statusText: xhr.statusText });
        };
        xhr.send();
      });
    }

    // Using text! to load the resource using text plugins
    static getTextResource(resource) {
      if (resource && resource.indexOf('text!') === 0) {
        return Utils.getResource(resource);
      }
      return Utils.getResource(`text!${resource}`);
    }

    /**
     * A JSON parser that throws an Error with the resource path.
     * @param  {String} jsonContent the JSON content of the resource
     * @param  {String} resource the path of the resource being parsed
     * @return {Object} the object
     */
    static parseJsonResource(jsonContent, resource = 'Unknown resource') {
      // An undefined, null or empty content is treated as a error. It's the case
      // for Android where no error is thrown but the content is empty when a file
      // is not found.
      if (!jsonContent) {
        throw new HttpError(404, new Error(`Empty file content for ${resource}.`));
      }

      try {
        return JSON.parse(jsonContent);
      } catch (error) {
        getLogger().error('Error when parsing', resource, error);
        throw (error);
      }
    }

    /**
     * Load a resource and parse it.
     * If the result of loading is falsy (undefined, null or '') it is not valid
     * and will throw an Http error.
     * If there is a parsing error, this method logs the name
     * of the resource failing and rethrow the error.
     *
     * @param  {String} resource the path to the resource
     * @return {Promise.<Object>}  a promise to the model object.
     */
    static loadAndParse(resource) {
      return Utils.getTextResource(resource).then((jsonContent) => Utils.parseJsonResource(jsonContent, resource));
    }

    // dynamically load and create the runtime environment, and cache the Promise
    static getRuntimeEnvironment() {
      if (!Utils.runtimeEnvironmentPromise) {
        Utils.runtimeEnvironmentPromise = this.getResource('vbRuntimeEnvironmentClass')
          .then((RuntimeEnvironmentClass) => new RuntimeEnvironmentClass());
      }
      return Utils.runtimeEnvironmentPromise;
    }

    /**
     * Will clone the object, but will copy primitive values, and objects
     * that are not directly derived from an object. For example, objects
     * whose parent class is not object will not be cloned, but passed by
     * reference.
     *
     * @param destination
     * @param source
     * @returns {*}
     */
    static cloneObject(source, destination) {
      let target = destination;

      // determine what to do if destination is not specified
      if (typeof destination === 'undefined') {
        if (!Utils.isCloneable(source)) {
          return source;
        }

        // otherwise create the right target
        target = Array.isArray(source) ? [] : {};
      }

      for (const name in source) {
        const copy = source[name];
        const src = target[name];
        let clone;

        // Prevent never-ending loop
        if (target === copy) {
          return target;
        }

        // Recurse if we're merging plain objects or arrays

        const copyIsArray = Array.isArray(copy);
        if (Utils.isCloneable(copy)) {
          if (copyIsArray) {
            // always clone into an empty array instead of overwriting the original
            clone = [];
          } else {
            clone = src && Utils.isObject(src) ? src : {};
          }

          // Never move original objects, clone them
          target[name] = Utils.cloneObject(copy, clone);

          // Don't bring in undefined values
        } else {
          target[name] = copy;
        }
      }

      return target;
    }

    /**
     * Empties all arrays in the value. This will return the new value, but it will also mutate
     * the value passed in.
     *
     * @param value a value, can be a primitive, object, or array
     * @param preserveNonEmpty
     */
    static emptyArrays(value, preserveNonEmpty = false) {
      if (!value) {
        return value;
      }
      let newValue = value;

      if (Array.isArray(value)) {
        newValue = [];
        if (preserveNonEmpty && !Utils.isStructureEmpty(value)) {
          Object.keys(value).forEach((k) => {
            const v = value[k];
            newValue.push(Utils.emptyArrays(v, preserveNonEmpty));
          });
        }
      } else if (Utils.isObject(value)) {
        Object.keys(value).forEach((key) => {
          const val = value[key];
          newValue[key] = Utils.emptyArrays(val, preserveNonEmpty);
        });
      }

      return newValue;
    }

    /**
     * Determines if an array has any set values on it's immediate properties.
     *
     * @private
     * @param structure
     */
    static isStructureEmpty(structure) {
      if (structure === undefined) {
        return true;
      }

      if (Array.isArray(structure)) {
        if (structure.length > 1) {
          return false;
        }

        const someChildrenHaveStructure = structure.some((v) => !Utils.isStructureEmpty(v));
        return !someChildrenHaveStructure;
      }

      if (Utils.isObject(structure)) {
        const keys = Object.keys(structure);
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          const val = structure[key];
          if (!Utils.isStructureEmpty(val)) {
            return false;
          }
        }
        return true;
      }

      return false;
    }

    static isPrototypeOfObject(test) {
      try {
        return test && (Object.getPrototypeOf(test) === Object.prototype || test.prototype === null);
      } catch (e) {
        // do nothing, happens on IE11 for non-objects
      }
      return false;
    }

    /**
     * Resolves every property of the object.
     * Also freeze the object at every level if the freeze option is set.
     *
     * @param {*} obj the object to traverse and resolve expression.
     * @param {Object} options object with only one possible property for now, freeze
     * { freeze: true } (the default) indicate all nested object should be frozen.
     * @returns {*}
     */
    static deepResolve(obj, options = { freeze: true }) {
      if (Utils.isObjectOrArray(obj)) {
        const propNames = Object.keys(obj); // don't use getOwnPropertyNames(); causes recursion on 'constants'

        propNames.forEach((name) => {
          obj[name] = Utils.deepResolve(obj[name], options);
        });

        return (options && options.freeze === true) ? Object.freeze(obj) : obj;
      }

      return Utils.resolveIfObservable(obj);
    }

    /**
     * Given a value that can be an observable, return the value by resolving the potential observable.
     * @param  {Object|Function} value
     * @return {*}
     */
    static resolveIfObservable(value) {
      return ko.isObservable(value) ? value() : value;
    }

    /**
     * Returns true if value is a primitive.
     *
     * @param value
     * @returns {boolean}
     */
    static isPrimitive(value) {
      return value !== Object(value);
    }

    /**
     * Returns true if the value is defined, non-null, and an object or array.
     *
     * @param value The value to test
     * @returns {boolean} True if the object is defined, non-null, and an object or array
     */
    static isObjectOrArray(value) {
      return value && typeof value === 'object';
    }

    /**
     * Return true if the value (or the actual instance) is of an extended type.
     * @param  {Object}  value
     * @return {Boolean}
     */
    static isExtendedType(value) {
      return (value && typeof value.isExtendedType === 'function' && value.isExtendedType());
    }

    /**
     * Returns true if the type declaration is a instance factory type. Factory types are also automatically
     * instance types. See isInstanceType.
     * instanceFactory types can be declared in 2 ways when used under types -
     * vb/InstanceFactory<foo/bar>, where foo/bar is an instance type (must have a '/')
     * vb/InstanceFactory, where the instance type is the same as the name of the type. example
     * "types": {
     *   "foo/bar": {
     *     "constructorType": "vb/InstanceFactory"
     *   }
     * }
     * @param typeDef the type definition that is typically defined under types
     */
    static isTypeDefInstanceFactory(typeDef) {
      return (typeof typeDef === 'string' && typeDef.indexOf(Constants.VariableTypePrefixes.INSTANCE_FACTORY) === 0);
    }

    /**
     * Returns whether or not the type is an instance (class) type, e.g., vb/ServiceDataProvider.
     *
     * @param type the type to check
     * @returns {boolean}
     */
    static isInstanceType(type) {
      // types are referenced within the same container in the following format:
      // <scope>:<name>
      // types are referenced as follows in the extensions:
      // <'base'|''|extensionId>/<scope>:<name>
      // so the actual type comes after the colon
      // for simple types without the scope, check the whole string
      return typeof type === 'string' && type.substring(type.indexOf(':') + 1).indexOf('/') >= 0;
    }

    /**
     * Returns true if the type is an object type, e.g., { "prop": "string" }.
     * Note that this will return true for the wildcard "object" type and false for the wildcard "any" type.
     *
     * @param type the type to test
     * @returns {boolean}
     */
    static isObjectType(type) {
      return typeof type === 'string' ? type === 'object' : Utils.isObject(type);
    }

    /**
     * Returns true if the type is an array type, e.g., string[] or [{"prop": "string"}].
     *
     * @param type the type to test
     * @returns {boolean}
     */
    static isArrayType(type) {
      return typeof type === 'string' && type.endsWith('[]') ? true : Array.isArray(type);
    }

    /**
     * Returns true if type is a primitive type, i.e., string, number or boolean.
     *
     * @param type the type to test
     * @returns {boolean}
     */
    static isPrimitiveType(type) {
      return ['string', 'number', 'boolean'].some((primitiveType) => type === primitiveType);
    }

    /**
     * Returns true if the type is an object or array type.
     *
     * @param type the type to test
     * @returns {boolean}
     */
    static isObjectOrArrayType(type) {
      return Utils.isObjectType(type) || Utils.isArrayType(type);
    }

    /**
     * Return true if the type is any or object wildcard type.
     *
     * @param type the type to test
     * @returns {boolean}
     */
    static isWildcardType(type) {
      return type === 'any' || type === 'object';
    }

    /**
     * Returns true if the type is the wildcard "any" type.
     *
     * @param type the type to test
     * @returns {boolean}
     */
    static isAnyType(type) {
      return type === 'any';
    }

    /**
     * Returns the row type of an array type and null if the type is not an array type.
     *
     * @param type the type the extract the row type
     * @returns {*}
     */
    static getArrayRowType(type) {
      if (Utils.isArrayType(type)) {
        return Array.isArray(type) ? type[0] : type.substring(0, type.length - 2);
      }

      return null;
    }

    /**
     * Return true if the value is cloneable which means it is an array or an object whose prototype is
     * Object.prototype.
     *
     * @param value The value to test
     * @returns {boolean} True if the value is cloneable
     */
    static isCloneable(value) {
      // original expression
      // !(!Utils.isObjectOrArray(value) || (!Array.isArray(value) && !Utils.isPrototypeOfObject(value)));
      return Utils.isObjectOrArray(value) && (Array.isArray(value) || Utils.isPrototypeOfObject(value));
    }

    /**
     * Return the deep merging of 2 objects
     * @param {Object} target
     * @param {...Object} ...sources
     */
    static mergeObject(target, ...sources) {
      if (!sources.length) {
        return target;
      }

      const source = sources.shift();

      if (Utils.isObject(target) && Utils.isObject(source)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in source) {
          if (Utils.isObject(source[key])) {
            if (!target[key]) {
              Object.assign(target, { [key]: {} });
            }
            Utils.mergeObject(target[key], source[key]);
          } else {
            Object.assign(target, { [key]: source[key] });
          }
        }
      }

      return Utils.mergeObject(target, ...sources);
    }

    /**
     * Retrieve the name of an item to use in a web storage.
     * @param  {string} applicationId
     * @param  {string} scopeName    the name of scope for this variable
     * @param  {string} namespace    the namespace of the variable
     * @param  {string} name         the name of the variable
     * @return {string}              the item name
     */
    static getWebStorageItemName(applicationId, scopeName, namespace, name) {
      return `orcl.vbcs.${applicationId}.${scopeName}.${namespace}.${name}`;
    }

    /**
     * Replace the current URL pathname without changing anything else on the URL.
     *
     * @param  {String} pathname the pathname to replace the current one
     * @return {String}          the new URL
     */
    static replaceUrlPathname(pathname) {
      // Use the anchor element trick to rebuild the full href by only replacing
      // pathname.
      const parser = document.createElement('a');
      parser.href = window.location.href;
      parser.pathname = pathname;
      window.history.replaceState(window.history.state, '', parser.href);

      return parser.href;
    }

    /**
     * Inject the content of a CSS in a style tag in the head section of the DOM
     * @param  {String} content the css content
     */
    static injectStyleTag(content) {
      if (!content) {
        return;
      }

      // eslint-disable-next-line no-param-reassign
      content = content.trim();
      if (!content) {
        return;
      }

      const elt = document.createElement('style');
      elt.type = 'text/css';
      elt.appendChild(document.createTextNode(content));

      document.head.appendChild(elt);
    }

    static diff(obj1, obj2) {
      return jsonDiff.diff(obj1, obj2);
    }

    /**
     * Applies changes to the object and returns a new object containing those changes.
     * Changes are descibed in the delta parameter as returned by Utils.diff(obj1, obj2).
     *
     * @see Utils#diff()
     *
     * @param {*} obj1
     * @param {Object} delta Value returned from Utils.diff(obj1, obj2)
     * @returns {object}
     */
    static patchObject(obj1, delta) {
      const clone = Object.assign({}, obj1);
      if (delta) {
        Object.keys(delta).forEach((propName) => {
          const diff = delta[propName];
          if (diff.length === 3) {
            delete clone[propName];
          } else if (diff.length === 2) {
            clone[propName] = diff[1];
          } else if (diff.length === 1) {
            clone[propName] = diff[0];
          }
        });
      }
      return clone;
    }

    /**
     * Changes the browser history state and URL. In case the browser is throttling
     * updates to the history (like chrome does) repeat attempt to change the history every
     * second until it is correctly applied.
     * @param  {Object} state
     * @param  {String} url
     * @param  {String} op    'replaceState' or 'pushState'
     * @return {Promise} a promise that resolves when the browser history is changed
     */
    static changeBrowserState(state, url, op) {
      // Do the push or replace
      window.history[op](state, '', url);
      // Check if it succeeded and retry after a second if not
      if (url !== window.location.href || this.diff(state, window.history.state)) {
        // logger.info('Failed changing browser state because of browser throttling, trying again in 1s.');
        return new Promise((resolve) => {
          window.setTimeout(() => {
            resolve();
          }, 1000);
        }).then(() => this.changeBrowserState(state, url, op));
      }

      return Promise.resolve();
    }

    static appendToUrlPathname(segment) {
      this.replaceUrlPathname(`${CommonUtils.addTrailingSlash(window.location.pathname)}${segment}`);
    }

    /**
     * Promise rejects on completion of setTimeout, message will be 'timeout'.
     * @param millisecs
     * @param error optional, will reject with Error('timeout') otherwise
     * @returns {Promise}
     */
    static getTimeoutPromise(millisecs, error) {
      return new Promise((_, reject) => {
        const e = error || new Error('timeout');
        setTimeout(() => reject(e), millisecs);
      });
    }

    /**
     * @param promiseOrValue typically, a promise, but can be any value (value will cause this to resolve immediately).
     * @param millisecs timeout length
     * @param errorObj optional an Error object that will be passed to reject(), and subsequently the catch() handler
     * @returns {Promise.<*>|Promise<R>}
     */
    static promiseRaceWithTimeout(promiseOrValue, millisecs, errorObj = new Error('promise timed out')) {
      const timeoutPromise = Utils.getTimeoutPromise(millisecs, errorObj);
      return Promise.race([promiseOrValue, timeoutPromise]);
    }

    /**
     * Take a load error, extract the status and build an error message.
     * @param  {Object} err an error thrown by Utils.getResource
     * @return {string} the formatted error message.
     */
    static formatLoadError(err) {
      const errInfo = { error: err };

      if (!err || !err.message) {
        errInfo.message = 'Unknown error';
      } else {
        errInfo.message = err.message;
        // In case of http error, remove the file URL in front of status
        if (err.statusCode) {
          const statusIndex = err.message.indexOf('status: ');
          if (statusIndex >= 0) {
            const status = err.message.substring(statusIndex);
            errInfo.message = `HTTP error ${status}`;
          }
        }
      }

      return errInfo.message;
    }

    /**
     * @returns {boolean} true if browser is Safari
     */
    static isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    /**
     * Determines whether the application is running in a mobile Safari browser.
     *
     * @returns {boolean}
     */
    static isMobileSafari() {
      return /mobile\/.*safari/i.test(navigator.userAgent);
    }

    /**
     * Reads a given file as binary Blob.
     * @param filePath file path to a file to read
     * @param mimeType optional, will use filename ext otherwise, defaults to application/octet-stream
     */
    static readBlob(filePath, mimeType) {
      const type = mimeType || Utils.getMimeType(filePath);
      const options = type ? { type } : undefined;

      return fetch(filePath)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          return null;
        });
    }

    /**
     * for now, a limited mapping of common image types, by file extension
     * returns application/octet-stream if not one of the few known types.
     *
     * @param filePath
     * @returns {*}
     */
    static getMimeType(filePath) {
      const ext = filePath.substr(filePath.lastIndexOf('.') + 1);
      switch (ext) {
        case 'jpe':
        case 'jpeg':
        case 'jpg': return 'image/jpeg';

        case 'svg': return 'image/svg+xml';

        case 'bmp': return 'image/bmp';

        case 'gif': return 'image/gif';

        default:
      }

      return 'application/octet-stream';
    }

    /**
     * @typedef {Object} Version
     * @property {number} major major portion of the version
     * @property {number} minor minor portion of the version
     * @property {number} patch patch portion of the version
     */
    /**
     * parses a version string and returns its parts
     * @param versionStr
     * @returns {Version} Parsed version object
     */
    static parseVersionString(versionStr) {
      if (typeof (versionStr) !== 'string') {
        return null;
      }

      const versionParts = versionStr.split('.');

      // parse from string or default to 0 if can't parse
      return {
        major: parseInt(versionParts[0], 10) || 0,
        minor: parseInt(versionParts[1], 10) || 0,
        patch: parseInt(versionParts[2], 10) || 0,
      };
    }

    /**
     * Finds list of parameter names in the string template.
     * Parameters are returned in the order they appear in the template.
     * Parameter names are not repeated.
     * Delimiter character are not included in the returned names.
     * Nesting delimeters is not allowed.
     *
     * Example:
     *  Utils.getTemplateParameterNames('{a}/{b}/{}/{a}/{c}) === ['a', 'b', 'c']'
     * @param {string} template
     * @param {string} [startDel] Starting parameter delimeter character. Default is '{'.
     * @param {string} [endDel] Ending parameter delimeter character. Default is '}'.
     * @returns {string[]}
     */
    static getTemplateParameterNames(template, startDel = '{', endDel = '}') {
      const keys = {};
      const start = startDel[0]; // make sure we have only one character
      const end = endDel[0]; // make sure we have only one character
      //  const match = template.match(/\{([^{}]*)\}/g);
      const match = template.match(new RegExp(`\\${start}([^\\${end}]*)\\${end}`, 'g'));
      if (match) {
        match.forEach((paramToken) => {
          if (paramToken.length > 2) {
            const paramName = paramToken.substring(1, paramToken.length - 1);
            keys[paramName] = true;
          }
        });
      }
      return Object.keys(keys);
    }

    /**
     * Deletes decorator properties form the object.
     * @param obj
     * @param filterFnc optional
     * @returns {*}
     */
    static removeDecorators(obj, filterFnc = NameFilters.isNotDecorator) {
      if (obj) {
        const o = obj;
        Object.keys(obj || {}).forEach((name) => {
          if (!filterFnc(name)) {
            delete o[name];
          }
        });
      }
      return obj;
    }

    /**
     * Flattens a nested object into a an object with one level of properties, with dots in the property names.
     * example:
     *   {
     *    one: {
     *      subone: {
     *        suboneone: 'x',
     *        subonetwo: 'y'
     *      }
     *    },
     *    two: {
     *      subtwo: 'z'
     *    }
     *   }
     * result:
     * {
     *   one.subone.suboneone: 'x',
     *   one.subone.subonetwo: 'y',
     *   two.subtwo: 'z'
     * }
     * @param object
     * @returns {*}
     */
    static flatten(object) {
      return Object.assign({}, ...(function _flatten(subobj, path = '') {
        if (subobj) {
          return [].concat(
            ...Object.keys(subobj).map((key) =>
              (typeof subobj[key] === 'object' ? _flatten(subobj[key], `${path}${path ? '.' : ''}${key}`) :
                ({ [`${path}${path ? '.' : ''}${key}`]: subobj[key] }))));
        }
        return [];
      }(object)));
    }

    /**
     * Converts File object into Blob.
     *
     * Used as a workaround for some Chrome request-cloning bug in Chrome service workers.
     *
     * @param file - file to be converted into Blob.
     * @returns (Blob} a blob duck typed as File.
     */
    static fileToBlob(file) {
      return new Blob([file], { // Duck Blob as File
        type: file.type,
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
      });
    }

    /**
     * Simple file extension removal.
     * @param fileName
     * @returns {string}
     */
    static removeFileExtension(fileName) {
      const fileParts = fileName.split('.');
      if (fileParts.length > 1) {
        fileParts.pop();
      }
      return fileParts.join('.');
    }

    /**
     * Simple file name removal.
     * @param fullPath
     * @returns {string}
     */
    static removeFileName(fullPath) {
      return URI(fullPath).filename('').toString();
    }

    /**
     * Create a new object, with accessors, that call the functions in the wrapped object.
     *
     * One use-case is to call the JET string functions, with no args, without needing to use a function expression,
     * to match the existing syntax behavior;
     *   ex.  $page.translations.foo.myString calls the JET <strings module>.myString function.
     *
     * @param functions {object} contains ONLY properties that are functions
     * @return {object} contains a set of properties with the same names as 'functions', where obj.x is functions.x()
     *
     * @private
     */
    static createFunctionWrappers(functions) {
      const getters = {};

      Object.keys(functions)
        .forEach((fncName) => {
          Object.defineProperty(getters, fncName, {
            // pass an empty object, to prevent exception; the generated functions don't guard against it.
            get: () => functions[fncName]({}),
          });
        });

      return getters;
    }

    /**
     * Utility to take hyphenated strings/filenames, remove the hyphens, and camel-case it.
     * ex: data-description-overlay.json => dataDescriptionOverlay
     *
     * @param hyphenatedString
     * @returns {string}
     */
    static hyphenatedToCamelCase(hyphenatedString) {
      // in cse its a file, remove any dot-something at the end, for convenience.
      const cleanedString = Utils.removeFileExtension(hyphenatedString);
      const parts = cleanedString.split('-')
        .map((part, index) => (index ? part.charAt(0).toUpperCase() + part.slice(1) : part));
      return parts.join('');
    }

    /**
     * Flattens the specified arguments producing an array with unique, defined elements. The order of the resulting
     * array is the order that the arguments are traversed.
     *
     * @example:
     * console.log(Utils.toFlatUniqueArray(1, 2, 1, 2, [1, [[1, undefined], 2, 3, [4]]])) // outputs [1, 2, 3, 4]
     *
     * @param args
     * @return {*[]}
     */
    static toFlatUniqueArray(...args) {
      // set accumulates the elements that are returned
      // arraySet avoids endless recursion if 2 arrays contain each other
      const doit = (set, arraySet, array) => {
        array.forEach((value) => {
          if (Array.isArray(value)) {
            const aSet = arraySet || new Set();
            if (!aSet.has(value)) {
              aSet.add(value);
              doit(set, aSet, value);
            }
          } else if (value !== undefined) {
            set.add(value);
          }
        });
        return set;
      };

      return [...doit(new Set(), undefined, args)];
    }

    /**
     * Parsed items of the qualified ID: {prefix}:{main}/{suffix}
     * @typedef {Object} QualifiedId
     * @property {string} [prefix]
     * @property {string} [main]
     * @property {string} [suffix]
     */

    /**
     * Separator tokens used for parsing qualified IDs: {prefix}{prefixToken}{main}{suffixToken}{suffix}
     *
     * @typedef {Object} QualifiedIdTokens
     * @property {string} [prefixToken=':']
     * @property {string} [suffixToken='/']
     */

    /**
     * Parses the specified string value to return an object with one or more of the following properties: 'prefix',
     * 'main', and 'suffix'. The return is undefined if 'value' is not a string or if no properties were set.
     *
     * The value is parsed as follows:
     * <ul>
     *   <li>If value has a 'suffix' token, the part after the token is the suffix and value now is only
     *   the part before the token.</li>
     *   <li>If value has a 'prefix' token, the part before the token is the prefix and value now is the part
     *   after the token.</li>
     *   <li>main is set to value</li>
     * </ul>
     *
     * Notice that a consequence of the above approach is that if the prefix has the 'suffix token', the
     * returned object has only the main and suffix token - in other words, the prefix cannot have the 'suffix token'.
     *
     * Clients can override the 'prefixToken' and 'suffixToken' with default to ':' and '/' respectively. Moreover,
     * setting a token to an empty string causes it to be ignored - so setting 'prefixToken' causes the prefix
     * handling to be ignored.
     *
     * @examples:
     * console.log(Utils.parseQualifiedIdentifier('p:m/s'));  // { prefix:p, main:m, suffix:s }
     * console.log(Utils.parseQualifiedIdentifier('m/s'));    // { main:m, suffix:s }
     * console.log(Utils.parseQualifiedIdentifier('p:m'));    // { prefix:p, main:m }
     * console.log(Utils.parseQualifiedIdentifier('m'));      // { main:m }
     * console.log(Utils.parseQualifiedIdentifier('/s'));     // { suffix:s }
     * console.log(Utils.parseQualifiedIdentifier('p:'));     // { prefix:p }
     * console.log(Utils.parseQualifiedIdentifier('p/:'));    // { main:p, suffix:: }
     *
     * @param {string} value - the value to be parsed
     * @param {QualifiedIdTokens} [tokens] - tokens used for parsing, defaults to "<i>:</i>", "<i>/</i>"
     * @return {QualifiedId|undefined} undefined if value is not a string or the object described above.
     */
    static parseQualifiedIdentifier(value, { prefixToken = ':', suffixToken = '/' } = {}) {
      if (typeof value === 'string') {
        let prefix;
        let main = '';
        let suffix;

        const suffixIndex = suffixToken.length > 0 ? value.indexOf(suffixToken) : -1;
        if (suffixIndex >= 0) {
          if (suffixIndex > 0) {
            main = value.substring(0, suffixIndex);
          }
          suffix = value.substring(suffixIndex + 1);
        } else {
          main = value;
        }

        const prefixIndex = prefixToken.length > 0 ? main.indexOf(prefixToken) : -1;
        if (prefixIndex >= 0) {
          if (prefixIndex > 0) {
            prefix = main.substring(0, prefixIndex);
          }
          main = main.substring(prefixIndex + 1);
        }

        let parsed;
        if (prefix) {
          (parsed || (parsed = {})).prefix = prefix;
        }
        if (main) {
          (parsed || (parsed = {})).main = main;
        }
        if (suffix) {
          (parsed || (parsed = {})).suffix = suffix;
        }
        return parsed;
      }
      return undefined;
    }

    /**
     * Replacer for use with JSON stringify that handles Set. Can be fixed to support other types if needed.
     * @param key
     * @param value
     * @return {*[]|*}
     */
    static setToJSONReplacer(key, value) {
      if (typeof value === 'object' && value instanceof Set) {
        return [...value];
      }
      return value;
    }

    /**
     * This method allows clients to run URIjs code without the risk of stumbling on some of the library issues.
     *
     * At this moment, this method handles:
     * - The fact that the port must be numeric which fails for uris with templates like
     *   'http://www.example.com:{port}'.
     *
     * @param {function(URI): T} handler - a function that receives a safe URIjs constructor
     * @returns {T} the value returned by the handler
     * @template T
     */
    static uriSafeOperation(handler) {
      const originalEnsureValidPort = URI.ensureValidPort;
      try {
        // eslint-disable-next-line no-param-reassign
        URI.ensureValidPort = () => undefined;
        return handler(URI);
      } finally {
        // eslint-disable-next-line no-param-reassign
        URI.ensureValidPort = originalEnsureValidPort;
      }
    }

    /**
     * Replaces all url() and @import paths within css text, with custom function map.
     *
     * @param {string} css The css string whose content would be searched for urls to replace
     * @param {(css: string, quote: string) => string} mapFunc The mapping function takes in the url and returns
     * the replacement for this url
     *
     * @returns {string} The css text with replacements
     */
    static replacePathInCSS(css, mapFunc) {
      // CssUrlReplacer is a umd module. Pull it in and expose it as a
      // simple util function.
      return CssUrlReplacer.default(css, mapFunc);
    }

    /**
     * Returns a simple map-like object, that enforces key = ID + namespace.
     * getAll() function returns all values with the given ID + namespaces, regadless of any extra varibales
     *
     * @returns {{
     *  get: (function(string, string): *),
     *  getAll: (function(string, string): *),
     *  set: (function(string, string, *): void),
     *  setWithVariables: (function(string, string, string, *): void),
     *  has: (function(string, string): boolean),
     *  hasWithVariables: (function(string, string, string): boolean),
     *  getWithVariables: (function(string, string, string): boolean),
     *  getKeys: (function(): string[]),
     *  getValues: (function(): *[]),
     *  delete: (function( function(string, string) : boolean ): void)
     * }}
     */
    static createNamespaceMap(defaultNamespace = Constants.ExtensionNamespaces.BASE) {
      /** @type Object<string, any> */
      const m = {};
      /** @type {function(string, string, (string|Object)=): string} */
      // eslint-disable-next-line max-len
      const computeKey = (id, namespace, variablesMapOrKey) => {
        let variablesKey = variablesMapOrKey;
        if (variablesMapOrKey && typeof variablesMapOrKey !== 'string') {
          const keys = Object.keys(variablesMapOrKey);
          variablesKey = keys.length > 0
            ? keys.map((key) => `${key}:${variablesMapOrKey[key]}`).join()
            : undefined;
        }
        return `${id}:${namespace || defaultNamespace}:${variablesKey || ''}`;
      };

      return {
        get: (id, namespace) => m[computeKey(id, namespace)],
        getAll: (id, namespace) => {
          const nonVarKey = computeKey(id, namespace);
          const allNonVarValues = [];
          Object.keys(m).forEach((k) => {
            if (k.startsWith(nonVarKey)) {
              allNonVarValues.push(m[k]);
            }
          });
          return allNonVarValues;
        },

        set: (id, namespace, value) => {
          m[computeKey(id, namespace)] = value;
        },

        setWithVariables: (id, namespace, variablesKey, value) => {
          m[computeKey(id, namespace, variablesKey)] = value;
        },

        has: (id, namespace) => !!m[computeKey(id, namespace)],

        hasWithVariables: (id, namespace, variablesKey) => !!m[computeKey(id, namespace, variablesKey)],
        getWithVariables: (id, namespace, variablesKey) => m[computeKey(id, namespace, variablesKey)],

        getKeys: () => Object.keys(m),
        getValues: () => Object.values(m),

        // delete entries when the condition callback returns true
        // @returns {function<id: {string}, namespace: {string}> : boolean}
        delete: (condition) => {
          const toDelete = [];
          Object.keys(m).forEach((k) => {
            const parts = k && k.split(':');
            if (condition(parts[0], parts[1])) {
              toDelete.push(k);
            }
          });
          toDelete.forEach((k) => {
            delete m[k];
          });
        },
      };
    }
  }

  // Must be set to allow SwaggerUtils.parseServiceText to work, as weel as Utils' TRAP related API.
  // This needs to be set externally because this module cannot depend on ConfigLoader in order to
  // avoid circular RequireJS dependencies.
  Utils.servicesGlobalVariableSupplier = () => [];

  // expose a static instance of filter utilities
  Utils.nameFilters = NameFilters;
  Utils.vbModuleObserver = IntersectionObserverUtils;

  return Utils;
});

