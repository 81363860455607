'use strict';

define('vbsw/private/plugins/tokenRelayHandlerPlugin',[
  'vbsw/api/fetchHandlerPlugin', 'vbsw/private/utils', 'vbsw/private/constants',
  'vbsw/private/plugins/authPreprocessorHandlerPlugin', 'vbc/private/log',
  'urijs/URI',
], (FetchHandlerPlugin, Utils, Constants, AuthPreprocessorHandlerPlugin, Log, URI) => {
  const logger = Log.getLogger('/vbsw/private/plugins/tokenRelayHandlerPlugin');

  /**
   * Handler plugin for handling token relay.
   */
  class TokenRelayHandlerPlugin extends FetchHandlerPlugin {
    constructor(context, params) {
      super(context);

      // used to cached in-memory version of the token for each token relay url
      this.cachedTokenPromises = {};

      // used to keep track of which token is currently invalid
      this.invalidateTokenPromises = {};

      // tolerance for the clock skew which can be configured by the app
      this.jwtClockSkewTolerance = (params && params.jwtClockSkewTolerance) || Constants.DEFAULT_CLOCK_SKEW_TOLERANCE;
    }

    static get tokenRelayUrlHeader() {
      return Constants.TOKEN_RELAY_URL_HEADER;
    }

    static get tokenRelayAuthenticationHeader() {
      return Constants.TOKEN_RELAY_AUTH_HEADER;
    }

    /**
     * This method is meant to be subclassed to add additional headers specified in tokenRelayAuthentication to
     * the token relay request.
     *
     * @param request the token relay request to add the headers
     * @param tokeRelayAuthentication contains additional headers to add to request
     * @param requestUrl the url for the original request
     */
    processTokenRelayAuthentication(request, tokeRelayAuthentication, requestUrl) {}

    /**
     * Get the authorization token header either from cache or from the token relay service.
     *
     * @param tokenRelayUrl the url for the token relay service
     * @param tokenRelayAuthentication additional authentication metadata for token relay
     * @param requestUrl the url for the original request
     * @param {boolean} tokenRelay2 True if the token relay is for new Spectra TRAP endpoint
     * @returns {Promise}
     */
    getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, requestUrl, tokenRelay2, tokenCacheKey) {
      // cache the promise for getting the token so we don't make multiple calls to the token relay service
      let cachedTokenPromise = this.cachedTokenPromises[tokenCacheKey];

      if (!cachedTokenPromise) {
        // first try retrieving the token from the state cache
        cachedTokenPromise = this.stateCache.get(tokenCacheKey).then((cachedToken) => {
          if (cachedToken) {
            return cachedToken;
          }

          const tokenRelayRequest = Utils.createTokenRelayRequest(tokenRelayUrl, tokenRelay2);

          // process additional metadata specified in tokeyRelayAuthentication
          this.processTokenRelayAuthentication(tokenRelayRequest, tokenRelayAuthentication, requestUrl);

          // use the fetchHandler to fetch the token so the request can be modified by the plugins such as
          // csrfTokenHandlerPlugin
          return this.fetchHandler.handleRequest(tokenRelayRequest).then((response) => {
            if (response.ok) {
              return response.json()
                .then((token) => this.cacheAuthToken(tokenCacheKey, token, tokenRelay2));
            }

            throw new Error(response.statusText);
          });
        }).catch((err) => {
          // log the error for debugging purpose
          console.error(err);

          // delete the promise if there's any error so we don't cache the failure state
          delete this.cachedTokenPromises[tokenCacheKey];
        });

        this.cachedTokenPromises[tokenCacheKey] = cachedTokenPromise;
      }

      return cachedTokenPromise.then((cachedToken) => {
        if (cachedToken) {
          if (Utils.checkJwtExpiration(cachedToken.expiration, this.jwtClockSkewTolerance)) {
            // the token has expired, invalidate it and fetch a new one
            return this.invalidateCachedToken(tokenRelayUrl, undefined, tokenCacheKey).then(() => {
              logger.info('Token for', tokenRelayUrl, 'has expired. Fetching a new token.');
              return this.getAuthHeader(tokenRelayUrl, tokenRelayAuthentication,
                requestUrl, tokenRelay2, tokenCacheKey);
            });
          }

          // return the actual auth header
          return cachedToken.token.authenticationHeader;
        }

        return null;
      });
    }

    /**
     * Invalidate the cached token for the given tokenRelayUrl. This method will return a promise that will
     * resolve to true if a request should be retried and false otherwise.
     *
     * @param tokenRelayUrl the url for the cached token to invalidate
     * @param wwwAuthHeader
     * @param tokenCacheKey Key to the access token cache
     * @returns {Promise.<Boolean>}
     */
    invalidateCachedToken(tokenRelayUrl, wwwAuthHeader, tokenCacheKey) {
      let invalidateTokenPromise = this.invalidateTokenPromises[tokenCacheKey];

      if (!invalidateTokenPromise) {
        // first, delete the token from the cache
        invalidateTokenPromise = this.stateCache.delete(tokenCacheKey).then(() => {
          // BUFP-21346: The invalidate request currently does not work so we are bypassing it and relying on always
          // fetching a fresh token using the no-cache header.
          if (wwwAuthHeader) {
            const body = {
              headers: {
                'WWW-Authenticate': wwwAuthHeader,
              },
            };

            const options = {
              method: 'POST',
              credentials: 'same-origin',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            };
            const request = new Request(`${tokenRelayUrl}/invalidate`, options);

            // make the invalidate request to the token relay service
            return this.fetchHandler.handleRequest(request)
            // only retry if we get a status 307
              .then(response => response.status === 307)
              .catch((err) => {
                // log the error and don't retry
                console.error(err);
                return false;
              });
          }

          logger.info('Authorization token for', tokenRelayUrl, 'is invalidated');

          return Promise.resolve(true);
        });

        this.invalidateTokenPromises[tokenCacheKey] = invalidateTokenPromise;
      }

      return invalidateTokenPromise.then((retry) => {
        // delete the invalidateTokenPromise
        delete this.invalidateTokenPromises[tokenCacheKey];

        // delete the cachedTokenPromise so the token can be refreshed
        delete this.cachedTokenPromises[tokenCacheKey];

        return retry;
      });
    }

    /**
     * The cached token is a wrapped version of the JWT token containing the extracted expiration
     * time and calculated server skew. This method will return a promise that resolves to the
     * wrapped token.
     *
     * @param tokenCacheKey Key to the access token cache
     * @param token the JWT token
     * @param {boolean} tokenRelay2 True if the token relay is for new Spectra TRAP endpoint
     * @returns {Promise}
     */
    cacheAuthToken(tokenCacheKey, token, tokenRelay2) {
      let authenticationHeader = token.authenticationHeader;
      let expiration = null;
      if (tokenRelay2) {
        // token.token_type is 'bearer' but the header prefix needs to be 'Bearer'
        authenticationHeader = `Bearer ${token.access_token}`;
        // TRAP service access token response provides expiration time explicitly
        // (https://www.rfc-editor.org/rfc/rfc6749#section-4.4.3)
        if (token.expires_in) {
          expiration = {
            time: Utils.getEpochTime() + token.expires_in,
            skew: 0,
          };
        }
      } else {
        authenticationHeader = token.authenticationHeader;
        // extract expiration from a JSON Web Token
        expiration = Utils.extractJwtExpiration(token.authenticationHeader);
      }
      const cachedToken = {
        token: { authenticationHeader },
        expiration,
      };

      return this.stateCache.put(tokenCacheKey, cachedToken).then(() => cachedToken);
    }

    /**
     * Append the token from the token relay service
     *
     * @param request the request to which to append the CSRF token
     * @returns {Promise}
     */
    handleRequestHook(request) {
      // get the url for the token relay service from the request header
      const tokenRelayUrl = request.headers.get(Constants.TOKEN_RELAY_URL_HEADER);

      if (tokenRelayUrl) {
        const tokenRelayAuthHeader = request.headers.get(Constants.TOKEN_RELAY_AUTH_HEADER);
        const tokenRelayAuthentication = tokenRelayAuthHeader ? JSON.parse(tokenRelayAuthHeader) : undefined;
        const tokenRelay2 = tokenRelayUrl ? request.headers.get(Constants.TRAP_2_ENABLED_HEADER) === 'true' : false;
        const cacheKey = this.getTokenCacheKey(request, tokenRelayUrl);

        return this.getAuthHeader(tokenRelayUrl, tokenRelayAuthentication, request.url, tokenRelay2, cacheKey)
          .then((authHeader) => {
            if (authHeader) {
              const { headers } = request;
              const altAuthHeaderName = headers.get(Constants.ALT_AUTHORIZATION_HEADER_NAME);
              const authHeaderName = altAuthHeaderName || 'Authorization';

              headers.set(authHeaderName, authHeader);
            }

            // failed to get the token and just let the original request fail
            return Promise.resolve();
          });
      }

      return Promise.resolve();
    }

    /**
     * Handles 401 response as result of a non-JWT token expiring. The returned promise will resolve
     * to true if the request needs to be retried and false otherwise.
     *
     * @param response
     * @param origRequest
     * @param request
     * @param client
     * @returns {Promise<Boolean>}
     */
    handleResponseHook(response, origRequest, request, client) {
      return Promise.resolve().then(() => {
        if (this.shouldRefreshAccessToken(response)) {
          const authHeader = request.headers.get('Authorization');

          logger.info('401 response detected for', origRequest.url, 'with authorization header', authHeader);

          if (authHeader) {
            // extract the token relay url from the request
            const tokenRelayUrl = AuthPreprocessorHandlerPlugin.getTokenRelayUrlFromRequest(origRequest);

            logger.info('Look up token relay url', tokenRelayUrl);

            if (tokenRelayUrl) {
              const cacheKey = this.getTokenCacheKey(request, tokenRelayUrl);

              const cachedTokenPromise = this.cachedTokenPromises[cacheKey] || Promise.resolve();

              return cachedTokenPromise.then((cachedToken) => {
                // if the auth header and the cached header match, that means the token has expired
                // so invalidate the token and retry the request
                if (cachedToken
                  && cachedToken.token.authenticationHeader === authHeader) {
                  logger.info('Invalidating cached authorization token');

                  return this.invalidateCachedToken(tokenRelayUrl, undefined, cacheKey).then(() => true);
                }

                return false;
              });
            }
          }
        }

        return false;
      });
    }

    /**
     * Return true if the response is a 401 response and its WWW-Authenticate header indicate invalid
     * access token.
     *
     * @param response the response to check
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    shouldRefreshAccessToken(response) {
      if (response.status === 401) {
        const wwwAuthHeader = response.headers.get('WWW-Authenticate');

        if (wwwAuthHeader && wwwAuthHeader.startsWith('Bearer')
          && wwwAuthHeader.includes('error="invalid_token')) {
          // Assuming bearer tokens, as per https://tools.ietf.org/html/rfc6750#section-3.1
          // Missing headers
          return true;
        }

        if (wwwAuthHeader && wwwAuthHeader.toLowerCase() === 'token') {
          // As per BUFP-42343 Salesforce doesn't implement the standard properly so
          // we are making an exception for a de-facto standard because of the size of the
          // API usage
          return true;
        }
      }

      return false;
    }

    /**
     * Based on the Request and the tokenRelay URL builds a key that can be used for
     * caching access token.
     *
     * @param {Request} request
     * @param {string} tokenRelayUrl
     * @returns {string}
     */
    // eslint-disable-next-line class-methods-use-this
    getTokenCacheKey(request, tokenRelayUrl) {
      // at RT token relay URL is unique per service, but at DT '$dt_service' is used for multiple service connections

      // at RT service connection can be bound only to one backend, so the request URL does not add any more
      // specificity to the cache key
      let reqKey = '';
      // at RT service connection can have only one auth type so we don't need the auth block
      // to play part in the cache key
      let authKey = '';

      // TODO: this should be handled by the DT's dtTokenRelayHandlerPlugin
      if (tokenRelayUrl.includes('/$dt_service')) {
        reqKey = new URI(request.url).domain();
        const tokenRelayAuthHeader = request.headers.get(Constants.TOKEN_RELAY_AUTH_HEADER);
        authKey = tokenRelayAuthHeader || '';
      }

      return tokenRelayUrl + reqKey + authKey;
    }
  }

  return TokenRelayHandlerPlugin;
});

