/* eslint-disable class-methods-use-this */

'use strict';

define('vbsw/private/serviceWorkerManagerClass',[
  'vbsw/private/utils',
  'vbsw/private/fetchHandler',
  'vbsw/private/pwa/pwaUtils',
  'vbsw/private/emulatedServiceWorkerWrapper',
  'vbsw/private/browserServiceWorkerWrapper',
], (Utils, FetchHandler, PwaUtils, EmulatedServiceWorkerWrapper, BrowserServiceWorkerWrapper) => {
  /**
   * This class is used to install service workers and provides apis for installing plugins.
   */
  class ServiceWorkerManagerClass {
    constructor() {
      this.serviceWorkerWrappers = {};
      this.serviceWorkerWrapper = undefined;
      this.config = undefined;
    }

    /**
     * Install the Service Worker and the Offline Handler
     * @param modulePath the path from which the service worker should its dependent modules
     * @param externalConfig config object containing external require config, plugins, and logConfig
     * @param Configuration applicationUrl and getBaseUrlFromConfig()
     * @returns {Promise}
     */
    install(modulePath, externalConfig, Configuration) {
      return this.installServiceWorkers(modulePath, externalConfig, Configuration)
        .then(() => this.installOfflineHandler());
    }

    /**
     * Install service workers.
     * @param modulePath the path from which the service worker should its dependent modules
     * @param externalConfig config object containing external require config, plugins, and logConfig
     * @param Configuration applicationUrl and getBaseUrlFromConfig()
     * @returns {Promise}
     */
    installServiceWorkers(modulePath, externalConfig, Configuration) {
      return this.getServiceWorkerConfig(modulePath, externalConfig, Configuration).then((config) => {
        const promises = [];
        config.scopes.forEach((scope) => {
          promises.push(this.installServiceWorker(scope, config));
        });

        return Promise.all(promises)
          // need to activate the url mapper client after installing the service worker
          .then((results) => this.activateUrlMapping()
            .then(() => results));
      });
    }

    /**
     * Activate the url mapper client running on the service worker.
     *
     * @returns {*}
     */
    activateUrlMapping() {
      return this.serviceWorkerWrapper ? this.serviceWorkerWrapper.activateUrlMapping() : Promise.resolve();
    }

    /**
     * Return an array of service worker scopes currently registered.
     *
     * @returns {Array}
     */
    getServiceWorkerScopes() {
      return Object.keys(this.serviceWorkerWrappers);
    }

    /**
     * Load and install the plugins for the given scope. The plugins should be an array of urls.
     *
     * @param plugins an array of urls for the plugins to install
     * @param reload if true, force reload all the plugins, otherwise, only reload plugins whose metadata has changed
     * @returns {Promise}
     */
    installServiceWorkerPlugins(plugins, reload = true) {
      // update the config.plugins so a newly found installing service worker can install them
      if (this.config) {
        this.config.plugins = plugins;
      }

      // protect against undefined serviceWorkerWrapper which can happen during unit tests
      return this.serviceWorkerWrapper ? this.serviceWorkerWrapper.installPlugins(plugins, reload) : Promise.resolve();
    }

    /**
     * Install the given message handler with the registered service workers. A message handler can implement
     * a set of methods with parameters that can be invoked via messages posted from the service worker. Each method
     * should return a promise that resolves to a response or rejects with an error. Note that multiple message
     * handlers can be installed.
     *
     * A method on the message handler can be invoked from the service worker by calling Utils.postMessage with a
     * message shown below:
     *
     * {
     *   method: 'methodToCall',
     *   args: ['foo']
     * }
     *
     * Note that only the last installed message handler containing the method specified in the message will be
     * invoked. This means a message handler can override the behavior of a handler registered before it. If this
     * is not the intended behavior, make sure there is no naming conflicts.
     *
     * @param messageHandler the message handler to install
     */
    installMessageHandler(messageHandler) {
      if (this.serviceWorkerWrapper) {
        this.serviceWorkerWrapper.installMessageHandler(messageHandler);
      } else {
        // put the message handler on a queue so it can be installed when the service worker is installed
        this.messageHandlerQueue = this.messageHandlerQueue || [];
        this.messageHandlerQueue.push(messageHandler);
      }
    }

    /**
     * Uninstall the given message handler.
     *
     * @param messageHandler message handler to uninstall
     */
    uninstallMessageHandler(messageHandler) {
      if (this.serviceWorkerWrapper) {
        this.serviceWorkerWrapper.uninstallMessageHandler(messageHandler);
      }
    }

    /**
     * Return a FetchHandler with all the plugins installed that can be used locally to process a fetch request.
     *
     * @returns {Promise}
     */
    getLocalFetchHandler() {
      if (!this.localFetchHandlerPromise) {
        this.localFetchHandlerPromise = Promise.resolve().then(() => {
          if (!this.config.isEmulated) {
            const localFetchHandler = new FetchHandler('/');

            // make sure we set up the require config for loading the plugins
            if (this.config.requireConfig) {
              requirejs.config(this.config.requireConfig);
            }

            return localFetchHandler.installPlugins(this.config.plugins, true)
              .then(() => {
                if (this.config.hasOfflineHandler) {
                  const offlineHandler = this.getDefaultOfflineHandlerUrl();
                  const browserFetch = fetch;
                  return localFetchHandler.installOfflineHandler({ offlineHandler })
                    .then(() => {
                      // restore fetch which is overridden to intercept requests by the persistence
                      // manager to the original to prevent double interception since requests are
                      // already being intercepted by the service worker
                      fetch = browserFetch;
                    });
                }
                return undefined;
              })
              .then(() => localFetchHandler);
          }

          // when running in emulation mode, return a pass-through fetch handler to avoid
          // double interceptions
          return {
            handleRequest(request) {
              return fetch(request);
            },
            hasOfflineHandler: this.config.hasOfflineHandler,
          };
        });
      }

      return this.localFetchHandlerPromise;
    }

    /**
     * Return the default url for the offline handler.
     *
     * @returns {string}
     */
    getDefaultOfflineHandlerUrl() {
      // get the base token url from vbInitConfig if any
      const vbConfig = window.vbInitConfig || {};

      if (vbConfig.CONTEXT_OFFLINE_HANDLER_PATH) {
        return vbConfig.CONTEXT_OFFLINE_HANDLER_PATH;
      }

      // requirejs baseUrl is already set in main.js using the BASE_URL and
      // the BASE_URL_TOKEN so we don't need to do any more path manipulation
      // to access app-flow.js. It is important to NOT terminate the file with
      // .js otherwise requirejs does not conform to the "baseUrl + paths"
      // rules for finding it.
      // https://requirejs.org/docs/api.html#jsfiles
      return 'app-flow';
    }

    /**
     * Determine if the application has an offline handler defined.
     *
     * @returns {Promise<boolean>}
     */
    hasOfflineHandler() {
      const handlerUrl = this.getDefaultOfflineHandlerUrl();

      return Utils.getResource(handlerUrl).then((Module) => {
        let module;
        if (Module) {
          // Module can be either a constructor or a singleton
          module = typeof Module === 'function' ? new Module() : Module;
        } else {
          module = {};
        }

        if (typeof module.createOfflineHandler === 'function') {
          return module.createOfflineHandler();
        }

        return false;
      }).catch(() => false);
    }

    /**
     * Load and install the offline handler.
     *
     * @returns {Promise}
     */
    installOfflineHandler(handlerUrl = this.getDefaultOfflineHandlerUrl()) {
      return this.serviceWorkerWrapper.installOfflineHandler(handlerUrl);
    }

    /**
     * Uninstall the offline handler.
     *
     * @returns {Promise}
     */
    uninstallOfflineHandler() {
      // make sure serviceWorkerWrapper exists to not break unit tests
      return this.serviceWorkerWrapper ? this.serviceWorkerWrapper.uninstallOfflineHandler() : Promise.resolve();
    }

    /**
     * Invoke syncOfflineData on the service worker.
     *
     * @param options any options to be passed to the persistence sync manager
     * @returns {Promise}
     */
    syncOfflineData(options) {
      return this.serviceWorkerWrapper.syncOfflineData(options);
    }

    /**
     * Invoke getOfflineSyncLog on the service worker.
     *
     * @returns {Promise}
     */
    getOfflineSyncLog() {
      return this.serviceWorkerWrapper.getOfflineSyncLog();
    }

    /**
     * Invoke insertOfflineSyncRequest on the service worker.
     *
     * @param request
     * @param options
     * @returns {Promise}
     */
    insertOfflineSyncRequest(request, options) {
      return this.serviceWorkerWrapper.insertOfflineSyncRequest(request, options);
    }

    /**
     * Invoke removeOfflineSyncRequest on the service worker.
     *
     * @param requestId
     * @returns {Promise}
     */
    removeOfflineSyncRequest(requestId) {
      return this.serviceWorkerWrapper.removeOfflineSyncRequest(requestId);
    }

    /**
     * Invoke updateOfflineSyncRequest on the service worker.
     *
     * @param requestId
     * @param request
     * @returns {Promise}
     */
    updateOfflineSyncRequest(requestId, request) {
      return this.serviceWorkerWrapper.updateOfflineSyncRequest(requestId, request);
    }

    /**
     * If the flag is true, force the PersistenceManager offline and vice versa.
     *
     * @param flag if true, force the PersistenceManager offline and vice versa.
     * @returns {Promise}
     */
    forceOffline(flag) {
      return this.serviceWorkerWrapper.forceOffline(flag);
    }

    /**
     * Set options on the offline handler.
     *
     * @param options options to be set on the offline handler
     * @returns {Promise}
     */
    setOfflineHandlerOptions(options) {
      return this.serviceWorkerWrapper.setOfflineHandlerOptions(options);
    }

    /**
     * Return a promise that resolves to true if the service worker is online, false otherwise.
     *
     * @return {Promise.<Boolean>}
     */
    isOnline() {
      return this.serviceWorkerWrapper.isOnline();
    }

    /**
     * Register the requests to be skipped by the service worker.
     *
     * @param requests an array of objects containing the url and method, e.g., [{ url: 'xxxx', method: 'GET' }]
     * @returns {Promise}
     */
    addRequestsToSkip(requests) {
      return this.serviceWorkerWrapper.addRequestsToSkip(requests);
    }

    /**
     * Deregister the requests to be skipped by the service worker.
     *
     * @param requests an array of objects containing the url and method, e.g., [{ url: 'xxxx', method: 'GET' }]
     * @returns {Promise}
     */
    removeRequestsToSkip(requests) {
      return this.serviceWorkerWrapper.removeRequestsToSkip(requests);
    }

    /**
     * Return the configuration object for the service worker. The configuration object should be specified on
     * the vbInitConfig as follow:
     *
     * SERVICE_WORKER_CONFIG: {
     *   scriptUrl: '/serviceWorker.js',
     *   scopes: ['/'],
     *   disabled: false
     * }
     *
     * The scriptUrl is the location where the service worker script is served. The scopes is an array of urls
     * that need to be relative to the scriptUrl. Otherwise, the service worker will fail to register. Both
     * scriptUrl and scope must be specified in order for them to take effect. Otherwise, a default scriptUrl
     * and scope will be derived from the CONTEXT_ROOT, APP_ID and APP_VERSION specified in vbInitConfig which
     * will be suitable for use in the VBCS environment.
     *
     * Setting the disabled flag to true will disable loading of the service worker and fall back to using
     * emulation.
     *
     * @param modulePath the path from which the service worker loads its dependent modules
     * @param externalConfig config object containing external require config, plugins, and logConfig
     * @param Configuration applicationUrl and getBaseUrlFromConfig()
     * @returns {Promise}
     * @private
     */
    getServiceWorkerConfig(modulePath, externalConfig, Configuration) {
      return Promise.resolve().then(() => {
        const vbConfig = window.vbInitConfig || {};
        const config = vbConfig.SERVICE_WORKER_CONFIG || {};

        config.applicationUrl = Configuration.applicationUrl;
        config.baseUrl = Configuration.getBaseUrlFromConfig(window.location, vbConfig, config.applicationUrl);

        // if scriptUrl or scope is not provided, derive them from context root, app url prefix, app id and app version
        if (!config.scriptUrl || !config.scopes) {
          const appId = vbConfig.APP_ID;
          const appVersion = vbConfig.APP_VERSION;
          const appUrlPrefix = vbConfig.APP_URL_PREFIX;

          let contextRoot = vbConfig.CONTEXT_ROOT || '/';
          if (!contextRoot.startsWith('/')) {
            contextRoot = `/${contextRoot}`;
          }
          if (!contextRoot.endsWith('/')) {
            contextRoot = `${contextRoot}/`;
          }

          let scriptRoot;
          if (appId && appVersion) {
            // derive the script root for the VBCS environment
            scriptRoot = `${contextRoot}${appUrlPrefix}/${appId}/${appVersion}/`;
          } else {
            scriptRoot = contextRoot;
          }

          config.scriptUrl = `${scriptRoot}vbServiceWorker.js`;
          config.scopes = [scriptRoot];
        }

        config.dtMode = vbConfig.IS_DT_MODE;
        config.log = vbConfig.LOG;

        // set up the JET path
        // NOTE: The service worker is dependent on the location of the JET to load requirejs. If this is not available,
        // the service worker will not be able to load any of the plugins.
        if (vbConfig.JET_CDN_PATH) {
          config.jetPath = `${vbConfig.JET_CDN_PATH}${vbConfig.JET_CDN_VERSION}/`;
        }

        config.baseUrlToken = vbConfig.BASE_URL_TOKEN;

        // determine the url for the offline handler
        if (vbConfig.CONTEXT_OFFLINE_HANDLER_PATH) {
          // allow override of the default path processing
          config.offlineHandler = vbConfig.CONTEXT_OFFLINE_HANDLER_PATH;
        } else {
          // strip 'index.html' off window.location.pathname
          let path = window.location.pathname;

          const htmlName = 'index.html';
          const index = path.indexOf(htmlName);
          path = index >= 0 ? path.substring(0, index) : path;

          // make sure the path ends with /
          path = !path.endsWith('/') ? `${path}/` : path;

          // use absolute url for loading the offline handler
          config.offlineHandler = `${path}${config.baseUrlToken ? `${config.baseUrlToken}/` : ''}app-flow.js`;
        }

        config.debug = vbConfig.DEBUG;

        // for DT, generate a nonce to trigger re-installation of the service worker
        if (vbConfig.IS_DT_MODE) {
          config.nonce = Utils.generateUniqueId();
        }

        // add PWA specific config
        if (PwaUtils.isMobilePwaConfig(vbConfig)) {
          config.isPwa = true;
          config.appPath = PwaUtils.computeAppPath(vbConfig);
          config.locale = PwaUtils.getLocale();

          // enable processing of request with vb-use-cached-response-when-offline header
          config.useCacheResponseWhenOfflineHeaderEnabled = true;
        } else if (PwaUtils.isWebPwaConfig(vbConfig)) {
          config.isWebPwa = true;
          config.registryUrl = vbConfig.REGISTRY_URL;
        }

        if (config.isWebPwa) {
          // enable processing of request with vb-use-cached-response-when-offline header for
          // extension registry url for web PWA
          config.useCacheResponseWhenOfflineHeaderEnabled = true;
        }

        // BUFP-23800: turn off service worker for Firefox due to CORS issue
        if (navigator.userAgent.toLowerCase()
          .indexOf('firefox') > -1) {
          config.disabled = true;
        }

        config.modulePath = modulePath;

        config.performanceConfig = vbConfig.PERFORMANCE_CONFIG;

        if (externalConfig) {
          config.requireConfig = externalConfig.requireConfig;
          config.plugins = externalConfig.plugins;
          config.logConfig = externalConfig.logConfig;
          config.userConfig = externalConfig.userConfig;
        }

        config.traceOptions = Utils.getTraceOptions(vbConfig);

        // determine if the application has an offline handler defined
        if (config.disabled === undefined) {
          return this.hasOfflineHandler().then((hasOfflineHandler) => {
            config.hasOfflineHandler = hasOfflineHandler;

            // determine if we should disable the service worker for non-PWA apps and
            // if there's no offline handler defined
            config.disabled = !config.isPwa && !config.hasOfflineHandler;

            // Since the old service worker is deprecated, stop running the offline
            // handler on the service worker for non-PWA apps by setting disableOnFetch to
            // true which will effectively kill any existing service worker and run the
            // offline handler on the main thread.
            if (!config.isPwa && config.hasOfflineHandler) {
              config.disableOnFetch = true;
            }

            return config;
          });
        }

        return config;
      });
    }

    /**
     * Post message the rest of the config during installation instead of clogging up the url.
     *
     * @param serviceWorker the service worker instance to which to post the config
     * @param config an option configuration for the service worker. If not specified, an existing configuration will
     * be used
     * @returns {Promise}
     */
    configureServiceWorker(serviceWorker, config) {
      console.log('ServiceWorkerManager calling configureServiceWorker');
      if (config) {
        this.config = config;
      }

      return Utils.postMessage(serviceWorker, {
        method: 'configureServiceWorker',
        args: [this.config],
      });
    }

    /**
     * Install the default service worker for the given scope. If service worker is not supported, it will be
     * emulated using the PersistenceManager from the offline toolkit running on the main thread.
     *
     * @param {String} scope the scope under which the service worker should be registered
     * @param config the config object for the service worker
     * @returns {Promise}
     * @protected
     */
    installServiceWorker(scope, config) {
      // remember the config
      this.config = config;

      // install the service worker if supported by the browser and not disabled
      if ('serviceWorker' in navigator && !config.disabled) {
        return new Promise((resolve, reject) => {
          const nonce = config.nonce ? `&nonce=${config.nonce}` : '';
          const baseUrlToken = config.baseUrlToken ? `&baseUrlToken=${config.baseUrlToken}` : '';
          let url;

          if (!config.disableOnFetch) {
            // make the jetPath, modulePath and baseUrlToken or nonce available on the script url
            // and the rest of the config object will be posted over during installation
            const jetPath = config.jetPath ? `jetPath=${config.jetPath.trim()}` : '';
            const modulePath = config.modulePath ? `&modulePath=${config.modulePath.trim()}` : '';
            const debug = config.debug ? '&debug=true' : '';
            const skipIgnoreSearch = config.skipIgnoreSearch ? `&skipIgnoreSearch=${config.skipIgnoreSearch}` : '';
            url = `${config.scriptUrl}?${jetPath}${modulePath}${baseUrlToken}${nonce}${debug}${skipIgnoreSearch}`;
          } else {
            url = `${config.scriptUrl}?disableOnFetch=true${baseUrlToken}${nonce}`;
          }
          url = encodeURI(url);

          navigator.serviceWorker.register(url, { scope })
            .then((registration) => {
              if (config.disableOnFetch) {
                // If disableOnFetch is true, immediately unregister the service worker and switch over to using
                // emulated service worker. Note that we need to do this so we can turn off existing service
                // workers.
                registration.unregister().then(() => {
                  this.emulateServiceWorker(scope).then(resolve).catch(reject);
                });
              } else {
                console.log('Service worker registration succeeded. Scope is', registration.scope, config.nonce);

                let serviceWorker;
                if (registration.installing) {
                  serviceWorker = registration.installing;

                  // post the config object to the installing service worker only if disableOnFetch is false
                  if (!config.disableOnFetch) {
                    this.configureServiceWorker(serviceWorker);
                  }
                } else if (registration.active) {
                  serviceWorker = registration.active;
                } else if (registration.waiting) {
                  serviceWorker = registration.waiting;
                }

                if (serviceWorker) {
                  // if the registered service worker is not in the installing state, register an onupdatefound
                  // listener to listen for the service worker upgrading to a new version
                  if (serviceWorker.state !== 'installing') {
                    registration.onupdatefound = () => {
                      console.log('Service worker update found.');

                      // discovered a new installing service worker, post the config object to it
                      if (registration.installing) {
                        this.configureServiceWorker(registration.installing);
                      }
                    };
                  }

                  console.log('Service worker state:', serviceWorker.state);

                  if (serviceWorker.state === 'activated') {
                    const serviceWorkerWrapper = this.addServiceWorkerWrapper(scope, serviceWorker, false, config,
                      registration);
                    resolve(serviceWorkerWrapper);
                  } else {
                    serviceWorker.onstatechange = (event) => {
                      // @ts-ignore ts(2339)
                      const state = event.target.state;
                      console.log('Service worker state changed:', state);

                      let serviceWorkerWrapper;
                      if (state === 'activated') {
                        console.log('Service worker is active.');
                        serviceWorkerWrapper = this.addServiceWorkerWrapper(scope, event.target, false, config,
                          registration);
                        resolve(serviceWorkerWrapper);
                      } else if (state === 'redundant') {
                        // If we get into the redundant state, that means the service worker we got from registration
                        // is no longer valid and no further state change will happen. Simply create a wrapper
                        // around navigator.serviceWorker.controller and resolve the promise and let the browser
                        // auto switch to the new version.
                        serviceWorkerWrapper = this.addServiceWorkerWrapper(scope, navigator.serviceWorker.controller,
                          false, config, registration);
                        resolve(serviceWorkerWrapper);
                      }
                    };
                  }
                } else {
                  console.log('No service worker found in registration.');

                  // fall back to using emulation
                  this.emulateServiceWorker(scope).then(resolve).catch(reject);
                }
              }
            })
            .catch((error) => {
              console.log('Service worker registration failed with', error);

              // fall back to using emulation
              this.emulateServiceWorker(scope).then(resolve).catch(reject);
            });
        });
      }

      // fall back to using emulation
      return this.emulateServiceWorker(scope);
    }

    /**
     * Emulate the service worker functionality by using the PersistenceManager from the offline toolkit
     *
     * @param scope scope for the service worker that vbServiceWorker.js would expect. This is not the same
     * as the actual service worker scope. For example, for a service worker registered at
     * https://masterdev-vboci.integration.test.ocp.oc-test.com/ic/builder/rt/jpCRUD/1.0/webApps/web/sw.js
     * emulated service worker scope would be "/ic/builder/rt/jpCRUD/1.0/"
     * and for an application using vanity url, with service worker registered at https://myvbtest.myvb.org/sw.js
     * emulated service worker scope would be "/ic/builder/rt/jpMob2_1_0/live/"
     * @return {Promise}
     * @protected
     */
    emulateServiceWorker(scope) {
      console.log('Emulating service worker using PersistenceManager.');

      // convenient way to find out we are running in emulation mode
      this.config.isEmulated = true;

      // need to set the scope here to '/' so we can intercept all requests
      return this.initializePersistenceManager().then((pm) => pm.register({ scope: '/' })
        .then((registration) => {
          const serviceWorkerWrapper = this.addServiceWorkerWrapper(scope, registration, true, this.config);

          // intercept fetch calls
          registration.addEventListener('fetch', (event) => {
            event.respondWith(serviceWorkerWrapper.handleRequest(event.request));
          });

          return serviceWorkerWrapper;
        }));
    }

    /**
     * Initialize the PersistenceManager from the offline toolkit.
     * @private
     */
    initializePersistenceManager() {
      // make sure we only initialize it once
      if (!this.persistenceManagerPromise) {
        this.persistenceManagerPromise =
          Utils.getResource('persist/persistenceManager')
            .then((PersistenceManager) => {
              this.persistenceManager = PersistenceManager;
              return PersistenceManager.init().then(() => PersistenceManager);
            });
      }

      return this.persistenceManagerPromise;
    }

    /**
     * Return the service worker wrapper for the given scope.
     *
     * @param {String} scope the scope for looking up the service worker wrapper
     * @returns {ServiceWorkerWrapper}
     * @private
     */
    getServiceWorkerWrapper(scope) {
      return this.serviceWorkerWrappers[scope];
    }

    /**
     * Create and add a ServiceWorkerWrapper instance.
     *
     * @param {String} scope the scope for the service worker wrapper
     * @param instance browser service worker instance or undefined if in emulation mode
     * @param config the service worker config
     * @param registration the service worker registration or undefined if in emulation mode
     * @returns {*}
     * @private
     */
    addServiceWorkerWrapper(scope, instance, isEmulated, config, registration) {
      let wrapper;
      if (!isEmulated) {
        wrapper = new BrowserServiceWorkerWrapper(scope, instance, config, registration);
      } else {
        // emulation mode
        wrapper = new EmulatedServiceWorkerWrapper(scope, instance, config, this.persistenceManager);
      }

      this.serviceWorkerWrappers[scope] = wrapper;

      // Since we never ended up supporting multiple service worker scopes, there is always only
      // one service worker wrapper. Therefore, we set it to this.serviceWorkerWrapper for easier
      // access
      this.serviceWorkerWrapper = wrapper;

      // install any message handlers that couldn't be installed before the service worker is available
      if (this.messageHandlerQueue) {
        this.messageHandlerQueue
          .forEach((messageHandler) => this.installMessageHandler(messageHandler));
        this.messageHandlerQueue = null;
      }

      return wrapper;
    }

    // eslint-disable-next-line  no-unused-vars
    addToCache(resources) {
      return Promise.resolve();
    }

    /**
     * Caching is enabled unless vbInitConfig.SERVICE_WORKER_CONFIG.disabled is set to true.
     * In all other cases, it is enabled
    */
    isCachingEnabled() {
      if (!globalThis.vbInitConfig || !globalThis.vbInitConfig.SERVICE_WORKER_CONFIG) {
        return false;
      }
      return globalThis.vbInitConfig.SERVICE_WORKER_CONFIG.disabled === true;
    }

    /**
     * Extension Caching is not supported
     */
    isExtensionCachingEnabled() {
      return false;
    }

    /**
     * Tell the service worker to setup an extension's cache.
     * Out of date caches for this extension are deleted.
     * The route for the extension's cache is based on the extension's baseUrl.
     * @param {string} id
     * @param {string} version
     * @param {string} baseUrl
     * @param {Array<string>} resources
     * @param {Object} [pwaInfo]
     * @returns {Promise}
     */
    // eslint-disable-next-line  no-unused-vars
    setupExtensionCache(id, version, baseUrl, resources, pwaInfo) {
      return Promise.resolve();
    }

    /**
     * Determine if the Service worker is in the process of caching extensions.
     * @returns {Promise<string>} 'IDLE' | 'CACHING' | 'WAITING'
     */
    getExtensionCachingStatus() {
      return Promise.resolve('IDLE');
    }
  }

  return ServiceWorkerManagerClass;
});

