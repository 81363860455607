'use strict';

define('vb/private/stateManagement/switcherBridge',[
  'knockout',
  'ojs/ojarraydataprovider',
  'vb/private/stateManagement/switcherElement',
  'vb/private/stateManagement/componentBridge',
  'vb/private/stateManagement/router/vbRouter',
  'vb/private/stateManagement/router',
  'vb/private/history',
  'vb/private/utils',
  'vb/private/constants',
  'vb/private/log',
], (ko, ArrayDataProvider, SwitcherElement, ComponentBridge, VbRouter, Router, History, Utils, Constants, Log) => {
  const logger = Log.getLogger('/vb/stateManagement/switcherBridge');

  let originalRootInstance;
  /**
   * Implementation of the switcher component
   */
  class SwitcherBridge extends ComponentBridge {
    constructor(container, props) {
      super();
      const allSwitcherElements = document.getElementsByTagName('oj-vb-switcher');
      if (allSwitcherElements.length > 1) {
        throw new Error('Cannot have more than one oj-vb-switcher in an application.');
      }

      this.composite = allSwitcherElements[0];

      // Verify that the parent page does not already have a nested flow
      const allFlows = Object.keys(container.flows);
      if (allFlows.length > 0) {
        allFlows.forEach((flowId) => {
          const flow = container.flows[flowId];
          try {
            flow.dispose();
          // eslint-disable-next-line no-empty
          } catch (err) {}
        });

        // It's important to undefine the router state, otherwise the vbRouter code
        // will try to undefined this state on the first navigation which will
        // invoke canExit and fail since the page is gone.
        // Also the router should not be removed because of case like the service app
        // where the same page can "switch" between an oj-vb-switcher or an oj-vb-content tag
        if (container.router) {
          // eslint-disable-next-line no-underscore-dangle
          container.router._stateId(null);
        }
      }

      this.parent = container;

      this.parent.switcherBridge = this;

      /**
       * Object to map switcheElement by id
       */
      this._switcherElts = {};

      /**
       * An observable array used by the Array DataProvider to store the oj-module index
       */
      this._modules = ko.observableArray();
      /**
       * An array DataProvider used by the oj-bind-for-each to enumerate through the oj-module
       */
      this._moduleADP = new ArrayDataProvider(this._modules, { keyAttributes: 'index' });

      /**
       * An array of oj-module configuration object used to configure each oj-module in the oj-bind-each
       * The value is modified by the rootPage when navigating in the switcher
       */
      this.moduleConfigs = [];

      /**
       * An array of switcher element id which index matches the oj-module
       */
      this.elementIds = [];

      this.props = props;
    }

    static getElementDoesNotExist(id) {
      return `Switcher element '${id}' does not exist in the array`;
    }

    connected(context) {
      // When the switcher component is connected, replace how the root router is retrieved
      // This is to support getting the switcher element root router instead of the base root router
      // when history.popstate is called.
      originalRootInstance = VbRouter.VbRootRouterClass.rootInstance;
      Object.defineProperty(VbRouter.VbRootRouterClass, 'rootInstance', {
        get() {
          const { routerName } = SwitcherElement.getFromHistory();
          if (routerName) {
            return this.allRootRouters[routerName];
          }

          return originalRootInstance;
        },
        enumerable: true,
        configurable: true,
      });

      super.connected(context);
    }

    disconnected(domNodes) {
      // Remove all elements in the switcher
      this.arrayRemove(this.props.data.data);
      // Restore the original root router.
      delete VbRouter.VbRootRouterClass.rootInstance;
      // Make it a configurable redonly
      Object.defineProperty(VbRouter.VbRootRouterClass, 'rootInstance', {
        value: originalRootInstance,
        enumerable: true,
        configurable: true,
      });

      super.disconnected(domNodes);
    }

    /**
     * Return the ArrayDataProvider that enumerate the oj-module to be used in the oj-bind-for-each
     *
     * @return {ArrayDataProvider}  the module array data provider.
     */
    getModuleArrayDataProvider() {
      return this._moduleADP;
    }

    /**
     * Switch the flow displayed when the current item changes.
     *
     * @param  {String}  id          The id of the switcher element to make current
     * @param  {String}  previousId  The previous switcher id
     * @param  {Boolean} replace     When true, do not push a state change on the browser
     * @return {Promise} a promise to an object with 2 properties: indexToShow, indexToHide
     */
    currentItemChanged(eventContext) {
      const { previousValue, value } = eventContext;
      // if the currentItem change was initiated internaly
      const isNotExternal = eventContext.updatedFrom !== 'external';
      const id = value;
      const previousId = previousValue;

      const results = {
        indexToShow: undefined,
        indexToHide: this.getElementIndex(previousId),
      };

      return Promise.resolve().then(() => {
        // a null item means to hide the current selection
        if (!id) {
          return undefined;
        }

        const switcherElt = this.getSwitcherElement(id);
        if (!switcherElt) {
          throw new Error(SwitcherBridge.getElementDoesNotExist(id));
        }

        results.indexToShow = switcherElt.index;

        const rootPage = switcherElt.root;

        Router.setBusyState();

        // This is the case where it's switching to a new element
        if (!rootPage.isInitialized()) {
          return switcherElt.initialize()
            .catch((error) => {
              Router.clearBusyState();
              throw error;
            });
        }

        const currentPage = switcherElt.getCurrentPage();

        return rootPage.rootRouter.updateState(currentPage && currentPage.fullPath, isNotExternal)
          .then(() => {
            if (currentPage) {
              // Assign the $application.currentPage variable
              rootPage.application.updateApplicationCurrentPageVariable(currentPage);
            }
          })
          .finally(() => {
            Router.clearBusyState();
          });
      }).then(() => results);
    }

    /**
     * Triggered in response of element(s) added to the array of switcher elements
     *
     * @param {Array<Object>} data The array of element(s) to add
     */
    arrayAdd(data) {
      if (Array.isArray(data) && data.length > 0) {
        logger.info('Adding', data.length, 'switcher element(s):', data);
        data.forEach((element) => {
          try {
            const switcherElt = new SwitcherElement(element, this);

            // Find the first available spot
            let index = this.elementIds.findIndex((id) => id === null);
            // and if there isn't one, add a new spot
            if (index === -1) {
              index = this.elementIds.length;

              this.moduleConfigs.push(ko.observable(Constants.blankModuleConfig));
              this._modules.push({ index });
              this.elementIds.push(null);
            }

            switcherElt.index = index;

            const id = switcherElt.id;
            this.elementIds[index] = id;
            this._switcherElts[id] = switcherElt;
          } catch (error) {
            // In case of error like when the id already exist, log the error but
            // don't throw so that other element can be added and the switcher is in consistent state
            logger.error(error);
          }
        });
      }
    }

    /**
     * Triggered in response of element(s) deleted from the array of switcher elements
     *
     * @param {Array<Object>} data The array of element(s) to add
     */
    arrayRemove(data) {
      if (Array.isArray(data) && data.length > 0) {
        logger.info('Removing', data.length, 'switcher element(s):', data);
        data.forEach((elt) => {
          // Only delete one element at a time
          const idToRemove = elt.id;
          const switcherElt = this.getSwitcherElement(idToRemove);
          const rootPage = switcherElt.root;

          if (rootPage.isInitialized()) {
            // switcherElt.toDelete = true;
            // TODO: Only call exit here and let disconnect do the reset of the delete
            this.deleteElement(switcherElt);
          } else {
            this.elementIds[switcherElt.index] = null;
            switcherElt.dispose();
            delete this._switcherElts[idToRemove];
          }
        });
      }
    }

    /**
     * A function recursively call a function on container
     *
     * @param   {Function}  callback   The callback
     * @param   {Container}    container  The container
     * @return  {Promise}
     */
    invokeCallback(callback, container) {
      return Promise.resolve().then(() => {
        if (container) {
          return container[callback]().then(() => {
            const parent = container.parent;
            if (parent !== container.rootPage) {
              return this.invokeCallback(callback, parent);
            }
            return undefined;
          });
        }
        return undefined;
      });
    }

    /**
     * Function to recursively invoke canExit on all container
     *
     * @param   {Container}  container  The container
     * @return  {Promise}
     */
    invokeCanExit(container) {
      return container.canExit().then((result) => {
        // If can exit, continue up the container hierarchy up to the root page
        if (result) {
          const parent = container.parent;
          if (parent && parent !== container.rootPage) {
            return this.invokeCanExit(parent);
          }
        }
        return result;
      });
    }

    /**
     * Delete a switcher element.
     * This is called after an element is removed from the switcher
     */
    deleteElement(switcherElt) {
      return Promise.resolve().then(() => {
        if (switcherElt) {
          const currentPage = switcherElt.getCurrentPage();

          return this.invokeCallback('exit', currentPage).then(() => {
            this.elementIds[switcherElt.index] = null;
            switcherElt.dispose();

            delete this._switcherElts[switcherElt.id];
          });
        }

        return undefined;
      });
    }

    /**
     * Return a promise that resolves to a boolean that is true when all the
     * containers inside the elment accept the delete operation.
     * This is going through the same process as beforeExit
     *
     * @param   {String}  id the id of the switcherelement to remove
     * @return  {Promise} a promise to a boolean
     */
    close(id) {
      return Promise.resolve().then(() => {
        if (id) {
          const switcherElt = this.getSwitcherElement(id);
          const rootPage = switcherElt.root;
          if (rootPage.isInitialized()) {
            // Cannot delete an item that is not current
            if (id !== this.currentItem) {
              return false;
            }

            const leafPage = switcherElt.getCurrentPage();
            if (leafPage) {
              return this.invokeCanExit(leafPage);
            }
          }
        }

        return true;
      });
    }

    /**
     * Called when the browser state stack changes
     * @return {boolean} true when the event is handled and the super should not be called.
     */
    handlePopState() {
      // Retrieve potential switcher element info from the browser history
      const { id } = SwitcherElement.getFromHistory();

      const executeDefault = this.dispatchBeforeHandlePopstateEvent(id);

      // By changing the value of currentItem, the switcher will automatically switch
      if (id && this.currentItem !== id
        // Make sure the element has not been previously deleted
        && this.getSwitcherElement(id)) {
        if (executeDefault) {
          this.currentItem = id;
        } else {
          Router.clearBusyState();
        }

        return true;
      }

      // when executeDefault is true, returns false to have the super class execute the default handling
      return !executeDefault;
    }

    /**
     * Dispatch the dispatchBeforeHandlePopstate event
     *
     * @param   {String} item the id of the element that will be switched to
     * @return  {boolean} true if the default handling of the event should be executed
     */
    dispatchBeforeHandlePopstateEvent(item) {
      const vbState = History.getStateBeforeHistoryPop().state || {};
      const previousPagePath = vbState.pagePath;
      const eventParams = {
        bubbles: true,
        cancelable: true,
        detail: {
          item,
          pagePath: History.getPagePath(),
          previousItem: this.currentItem,
          previousPagePath,
        },
      };

      return this.composite.dispatchEvent(new CustomEvent('vbBeforePopState', eventParams));
    }

    /**
     * Retrieve the switcher component currentItem property
     *
     * @type {String}
     */
    get currentItem() {
      return this.props.currentItem;
    }

    /**
     * Change the switcher component currentItem property
     * Because it's a write-back property assignment need to be done on props.currentItem
     *
     * @type {String}
     */
    set currentItem(value) {
      this.props.currentItem = value;
    }

    /**
     * Retrieve a switcher element given its id
     *
     * @param  {String}  elementId  The element identifier
     * @return {SwitcherElement}  the switcher element
     */
    getSwitcherElement(id) {
      return this._switcherElts[id];
    }

    /**
     * Return the ojModule index from an element id
     *
     * @param  {String}  id  a switcher element id
     * @return {number}  The element index in the array of ojModule
     */
    getElementIndex(id) {
      const switcherElt = this.getSwitcherElement(id);
      return switcherElt && switcherElt.index;
    }

    /**
     * Retrieve the current switcher element
     *
     * @return {SwitcherElement} the current switcher element
     */
    getCurrentSwitcherElement() {
      return this.getSwitcherElement(this.currentItem);
    }

    /**
     * Return the oj-module configuration object given the index of the
     * oj-module in the oj-bind-for-each
     *
     * @param  {Number} index The index in the array ojModule
     * @return {Object} The ojModule configuration at this index
     */
    getModuleConfigFromIndex(index) {
      return this.moduleConfigs[index];
    }
  }

  return SwitcherBridge;
});

